import { useTranslation } from "react-i18next";

import Typography from "app/components/Typography/Typography";

import {
  ChangelogContainer,
  ChangelogSubtitleContainer,
  ChangelogSubtitle,
  ChangelogChip,
  ChangelogUl,
  ChangelogLi,
} from "./Changelog.styles";

export default function Changelog() {
  const { t } = useTranslation();

  return (
    <ChangelogContainer>
      <Typography as="h1" variant="title2">
        {t("changelog.title")}.
      </Typography>

      <Typography as="h2" variant="paragraph1" style={{ textAlign: "center" }}>
        {t("changelog.subtitle")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.6.2022</ChangelogSubtitle>

        <ChangelogChip>v1.5.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.5.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.5.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.5.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>12.5.2022</ChangelogSubtitle>

        <ChangelogChip>v1.4.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.4.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.4.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.4.0.descriptionText2")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.4.0.descriptionText3")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>28.3.2022</ChangelogSubtitle>

        <ChangelogChip>v1.3.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.3.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.3.0.descriptionText1")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.3.0.improvementsText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.3.2022</ChangelogSubtitle>

        <ChangelogChip>v1.2.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.2.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.2.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.2.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>25.12.2021</ChangelogSubtitle>

        <ChangelogChip>v1.1.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.1.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.1.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.1.0.descriptionText2")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.1.0.descriptionText3")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>21.10.2021</ChangelogSubtitle>

        <ChangelogChip>v1.0.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v1.0.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.0.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v1.0.0.descriptionText2")}.
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v1.0.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v1.0.0.li2")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v1.0.0.li3")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v1.0.0.li4")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>9.9.2021</ChangelogSubtitle>

        <ChangelogChip>v0.23.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.23.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.23.0.descriptionText1")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.23.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.23.0.li2")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>1.8.2021</ChangelogSubtitle>

        <ChangelogChip>v0.22.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.22.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.22.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.22.0.descriptionText2")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.22.0.descriptionText3")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.7.2021</ChangelogSubtitle>

        <ChangelogChip>v0.21.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.21.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.21.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>30.6.2021</ChangelogSubtitle>

        <ChangelogChip>v0.20.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.20.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.20.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.20.0.descriptionText2")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.20.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.20.0.li2")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>8.6.2021</ChangelogSubtitle>

        <ChangelogChip>v0.19.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.19.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.19.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>18.5.2021</ChangelogSubtitle>

        <ChangelogChip>v0.18.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.18.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.18.0.descriptionText1")}:
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.18.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.18.0.li2")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>12.3.2021</ChangelogSubtitle>

        <ChangelogChip>v0.17.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.17.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.17.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.17.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>9.3.2021</ChangelogSubtitle>

        <ChangelogChip>v0.16.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.16.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.16.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.16.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>15.2.2021</ChangelogSubtitle>

        <ChangelogChip>v0.15.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.15.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.15.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.15.0.descriptionText2")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.15.0.descriptionText3")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.15.0.improvementsText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.15.0.improvementsText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>25.1.2021</ChangelogSubtitle>

        <ChangelogChip>v0.14.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.14.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.14.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.14.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>7.1.2021</ChangelogSubtitle>

        <ChangelogChip>v0.13.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.13.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.13.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.13.0.descriptionText2")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.13.0.improvementsText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.12.2020</ChangelogSubtitle>

        <ChangelogChip>v0.12.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.12.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.12.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.12.0.descriptionText2")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.12.0.improvementsText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.12.2020</ChangelogSubtitle>

        <ChangelogChip>v0.11.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.11.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.11.0.descriptionText1")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.11.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.11.0.li2")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>1.12.2020</ChangelogSubtitle>

        <ChangelogChip>v0.10.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.10.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.10.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>26.11.2020</ChangelogSubtitle>

        <ChangelogChip>v0.9.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.9.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.9.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>25.11.2020</ChangelogSubtitle>

        <ChangelogChip>v0.8.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.8.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.8.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>16.11.2020</ChangelogSubtitle>

        <ChangelogChip>v0.7.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.7.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.7.0.descriptionText1")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.7.0.improvementsText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>31.10.2020</ChangelogSubtitle>

        <ChangelogChip>v0.6.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.6.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.6.0.descriptionText1")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.6.0.improvementsText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>26.10.2020</ChangelogSubtitle>

        <ChangelogChip>v0.5.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.5.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.5.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.5.0.descriptionText2")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.5.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.5.0.li2")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.5.0.li3")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>28.9.2020</ChangelogSubtitle>

        <ChangelogChip>v0.4.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.4.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.4.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.4.0.descriptionText2")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>27.8.2020</ChangelogSubtitle>

        <ChangelogChip>v0.3.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.3.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.3.0.descriptionText1")}.
      </Typography>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>24.8.2020</ChangelogSubtitle>

        <ChangelogChip>v0.2.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.2.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.2.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.2.0.descriptionText2")}.
      </Typography>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.improvementsTitle")}
      </Typography>

      <ChangelogUl>
        <ChangelogLi>{t("changelog.v0.2.0.li1")}.</ChangelogLi>

        <ChangelogLi>{t("changelog.v0.2.0.li2")}.</ChangelogLi>
      </ChangelogUl>

      <ChangelogSubtitleContainer>
        <ChangelogSubtitle style={{ margin: 0 }}>14.7.2020</ChangelogSubtitle>

        <ChangelogChip>v0.1.0</ChangelogChip>
      </ChangelogSubtitleContainer>

      <Typography
        as="h3"
        variant="title3"
        style={{ width: "100%", textAlign: "left", maxWidth: 620 }}
      >
        {t("changelog.v0.1.0.title")}
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.1.0.descriptionText1")}.
      </Typography>

      <Typography
        as="p"
        variant="paragraph2"
        style={{
          width: "100%",
          maxWidth: 620,
          marginBottom: 16,
          textAlign: "left",
        }}
      >
        {t("changelog.v0.1.0.descriptionText2")}.
      </Typography>
    </ChangelogContainer>
  );
}
