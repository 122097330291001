import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavbarContainer = styled.nav<{ isInTop: boolean }>`
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 200;
  border-bottom: ${({ isInTop }) => (isInTop ? "none" : "1px solid #8f9bb729")};
  height: 64px;
  background-color: ${({ isInTop }) =>
    isInTop ? "none" : "rgba(22, 22, 26, 0.7)"};
  transition: background-color 0.5s, box-shadow 0.5s;
  backdrop-filter: ${({ isInTop }) => (isInTop ? "none" : "blur(5px)")};
`;

export const NavbarInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  align-items: center;
  margin: 0 auto;
  padding: 0 72px;
  max-width: 1200px;

  @media (max-width: 600px) {
    padding: 0 42px;
  }
`;

export const NavbarLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NavbarLink = styled(NavLink)`
  font-family: "SFPro";
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #d0d6e0;
  font-size: 16px;
  line-height: 1.4;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }

  &:not(:last-child) {
    margin-right: 32px;
  }

  @media (max-width: 600px) {
    text-align: center;
    margin: 8px 0;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;
