import { useTranslation } from "react-i18next";
import { InstagramIcon } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import Typography from "app/components/Typography/Typography";
import { GithubIcon, LinkedInIcon } from "app/assets/icons";
import YoutubeIcon from "app/assets/icons/YoutubeIcon";

import {
  FooterContainer,
  FooterInnerContainer,
  FooterLinksContainer,
  FooterCopyrightContainer,
  FooterSocialMediaLink,
  FooterSectionsContainer,
  FooterLink,
} from "./Footer.styles";

export default function Footer() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const copyrightDate = new Date();
  const copyrightYear = copyrightDate.getFullYear();

  return (
    <FooterContainer>
      <FooterInnerContainer>
        <FooterCopyrightContainer>
          <Typography
            as="p"
            variant="paragraph2"
            style={{ margin: "0", textAlign: mobile ? "center" : "left" }}
          >
            {t("footer.copyrightText", { copyrightYear })}
          </Typography>
        </FooterCopyrightContainer>

        <FooterLinksContainer>
          <FooterLink to="about">{t("footer.aboutLinkText")}</FooterLink>
          <FooterLink to="plans">{t("footer.plansLinkText")}</FooterLink>
          <FooterLink to="changelog">
            {t("footer.changelogLinkText")}
          </FooterLink>
        </FooterLinksContainer>

        <FooterSectionsContainer>
          <FooterSocialMediaLink
            target="_blank"
            href="https://www.instagram.com/grafito.app/"
          >
            <InstagramIcon height="24px" color="#8f9ab7" />
          </FooterSocialMediaLink>

          <FooterSocialMediaLink
            target="_blank"
            href="https://www.linkedin.com/company/71194720"
          >
            <LinkedInIcon height="24px" />
          </FooterSocialMediaLink>

          <FooterSocialMediaLink
            target="_blank"
            href="https://github.com/grafito-app"
          >
            <GithubIcon height="24px" />
          </FooterSocialMediaLink>

          <FooterSocialMediaLink
            target="_blank"
            href="https://www.youtube.com/channel/UCvrphOoNdgpU2i0t9-KnSvA"
          >
            <YoutubeIcon height="24px" />
          </FooterSocialMediaLink>
        </FooterSectionsContainer>
      </FooterInnerContainer>
    </FooterContainer>
  );
}
