import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};
  border-top: 1px solid ${({ theme }) => theme.colorsPalette.gray.darker};
`;

export const FooterInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 72px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 40px;
  }
`;

export const FooterCopyrightContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 4px;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    flex-direction: column;
    position: unset;
    transform: unset;
    margin: 16px 0;
  }
`;

export const FooterLink = styled(NavLink)`
  font-family: "SFPro";
  text-decoration: none;
  color: #8f9ab7;
  font-size: 16px;
  line-height: 1.4;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }

  &:not(:last-child) {
    margin-right: 32px;
  }

  @media (max-width: 600px) {
    text-align: center;
    margin: 2px 0;
    color: #d0d6e0;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const FooterSectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const FooterSocialMediaLink = styled.a`
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 10px;
  color: #8f9ab7;
  transition: 0.5s color, 0.5s background-color;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:hover {
    color: #040f1a;
    background-color: #fff;
  }
`;
