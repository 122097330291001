import { Button as BaseButton } from "kosmos-ui";
import { TButtonProps } from "kosmos-ui/lib/components/Button/types";
import { CSSProperties } from "styled-components";

export default function Button({ style: baseStyle, ...props }: TButtonProps) {
  const style: CSSProperties = {
    fontSize: 18,
    ...baseStyle,
  };

  return <BaseButton {...props} style={style} />;
}
