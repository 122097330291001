import { Routes as BaseRoutes, Route } from "react-router-dom";

import AboutUsSection from "app/sections/AboutUs/AboutUs";
import ChangelogSection from "app/sections/Changelog/Changelog";
import PlansSection from "app/sections/Plans/Plans";
import Home from "app/sections/Home/Home";
import Header from "app/sections/Header/Navbar";
import Footer from "app/sections/Footer/Footer";
import { useScrollToTop } from "app/hooks/general.hooks";

export default function Routes() {
  useScrollToTop();

  return (
    <>
      <Header />

      <BaseRoutes>
        <Route path="/" element={<Home />} />

        <Route path="about" element={<AboutUsSection />} />

        <Route path="plans" element={<PlansSection />} />

        <Route path="changelog" element={<ChangelogSection />} />
      </BaseRoutes>

      <Footer />
    </>
  );
}
