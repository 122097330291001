import { useTranslation } from "react-i18next";

import Button from "app/components/Button/Button";
import Typography from "app/components/Typography/Typography";
import { theme } from "kosmos-ui/lib";
import GrafitoIsotypeIcon from "kosmos-ui/lib/icons/GrafitoIsotype";

import { StartTodayContainer } from "./StartToday.styles";

export default function StartToday() {
  const { t } = useTranslation();

  return (
    <StartTodayContainer>
      <GrafitoIsotypeIcon
        height="88"
        style={{
          marginBottom: 24,
          backgroundColor: theme.colorsPalette.black.default,
          padding: 32,
          borderRadius: 24,
        }}
      />

      <Typography as="h1" variant="title2">
        {t("home.startToday.title")}
      </Typography>

      <Typography as="p" variant="paragraph1" style={{ textAlign: "center" }}>
        {t("home.startToday.subtitle")}.
      </Typography>

      <Button
        variant="primary"
        href="mailto:clemente@grafito.app?subject=I%20want%20to%20start%20with%20grafito%20.app"
      >
        {t("home.startToday.startButtonText")}
      </Button>
    </StartTodayContainer>
  );
}
