import { useTranslation } from "react-i18next";
import { theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import BODashImg from "app/assets/images/product-features/bo-dash.png";
import BOShoppersImg from "app/assets/images/product-features/bo-shoppers.png";
import BOOrdersImg from "app/assets/images/product-features/bo-orders.png";
import GalleryHomeImg from "app/assets/images/product-features/gallery-home.png";
import GalleryProfileImg from "app/assets/images/product-features/gallery-profile.png";
import GalleryCrossSells1Img from "app/assets/images/product-features/gallery-crosssells1.png";
import GalleryCrossSells2Img from "app/assets/images/product-features/gallery-crosssells2.png";
import GalleryCrossSells3Img from "app/assets/images/product-features/gallery-crosssells3.png";
import OSProductsDetailsImg from "app/assets/images/product-features/os-product-details.png";
import OSCheckoutImg from "app/assets/images/product-features/os-checkout.png";
import BOShoppersDesktopImg from "app/assets/images/product-features/bo-shoppers-desktop.png";
import BOShoppersProfileImg from "app/assets/images/product-features/bo-shoppers-profile.png";

import Typography from "app/components/Typography/Typography";

import {
  FeaturesContainer,
  FeaturesSubsectionContainer,
  FeaturesTextsContainer,
  FeaturesIconsContainer,
  FeaturesImg,
} from "./Features.styles";

export default function Features() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  return (
    <FeaturesContainer>
      <FeaturesSubsectionContainer>
        <FeaturesTextsContainer>
          <Typography
            as="h1"
            variant="title2"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "left",
            }}
          >
            {t("home.features.businessManagement.title")}.
          </Typography>

          <Typography as="p" variant="paragraph1" style={{ maxWidth: 380 }}>
            {t("home.features.businessManagement.descriptionText")}.
          </Typography>
        </FeaturesTextsContainer>

        <FeaturesIconsContainer>
          <FeaturesImg
            src={BODashImg}
            style={{
              position: "absolute",
              bottom: 0,
              right: 80,
              height: 312,
            }}
          />

          <FeaturesImg
            src={BOShoppersImg}
            style={{
              top: 0,
              right: 180,
              height: 360,
            }}
          />

          <FeaturesImg
            src={BOOrdersImg}
            style={{
              top: 0,
              right: 0,
              height: 360,
            }}
          />
        </FeaturesIconsContainer>
      </FeaturesSubsectionContainer>

      <FeaturesSubsectionContainer>
        <FeaturesIconsContainer>
          <FeaturesImg
            src={GalleryHomeImg}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: 312,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />

          <FeaturesImg
            src={GalleryProfileImg}
            style={{
              position: "absolute",
              left: 380,
              top: 28,
              height: 360,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />
        </FeaturesIconsContainer>

        <FeaturesTextsContainer
          style={{ alignItems: mobile ? "center" : "flex-end" }}
        >
          <Typography
            as="h1"
            variant="title2"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "right",
            }}
          >
            {t("home.features.socialNetwork.title")}.
          </Typography>

          <Typography
            as="p"
            variant="paragraph1"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "right",
            }}
          >
            {t("home.features.socialNetwork.descriptionText")}.
          </Typography>
        </FeaturesTextsContainer>
      </FeaturesSubsectionContainer>

      <FeaturesSubsectionContainer>
        <FeaturesTextsContainer>
          <Typography
            as="h1"
            variant="title2"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "left",
            }}
          >
            {t("home.features.collaborative.title")}.
          </Typography>

          <Typography as="p" variant="paragraph1" style={{ maxWidth: 380 }}>
            {t("home.features.collaborative.descriptionText")}.
          </Typography>
        </FeaturesTextsContainer>

        <FeaturesIconsContainer>
          <FeaturesImg
            src={GalleryCrossSells1Img}
            style={{
              height: 380,
              position: "absolute",
              top: 0,
              right: 0,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />

          <FeaturesImg
            src={GalleryCrossSells3Img}
            style={{
              height: 380,
              position: "absolute",
              top: 0,
              right: 431,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />

          <FeaturesImg
            src={GalleryCrossSells2Img}
            style={{
              height: 480,
              position: "absolute",
              top: -52,
              right: 192,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />
        </FeaturesIconsContainer>
      </FeaturesSubsectionContainer>

      <FeaturesSubsectionContainer>
        <FeaturesIconsContainer>
          <FeaturesImg
            src={OSProductsDetailsImg}
            style={{
              position: "absolute",
              bottom: 0,
              left: 32,
              height: 312,
            }}
          />

          <FeaturesImg
            src={OSCheckoutImg}
            style={{
              position: "absolute",
              left: 0,
              bottom: 32,
              height: 360,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />
        </FeaturesIconsContainer>

        <FeaturesTextsContainer
          style={{ alignItems: mobile ? "center" : "flex-end" }}
        >
          <Typography
            as="h1"
            variant="title2"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "right",
            }}
          >
            {t("home.features.ownVirtualSpace.title")}.
          </Typography>

          <Typography
            as="p"
            variant="paragraph1"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "right",
            }}
          >
            {t("home.features.ownVirtualSpace.descriptionText")}.
          </Typography>
        </FeaturesTextsContainer>
      </FeaturesSubsectionContainer>

      <FeaturesSubsectionContainer>
        <FeaturesTextsContainer>
          <Typography
            as="h1"
            variant="title2"
            style={{
              maxWidth: 380,
              textAlign: mobile ? "center" : "left",
            }}
          >
            {t("home.features.analytics.title")}.
          </Typography>

          <Typography as="p" variant="paragraph1" style={{ maxWidth: 380 }}>
            {t("home.features.analytics.descriptionText")}.
          </Typography>
        </FeaturesTextsContainer>

        <FeaturesIconsContainer>
          <FeaturesImg
            src={BOShoppersDesktopImg}
            style={{
              height: 320,
              position: "absolute",
              top: 0,
              right: 0,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />

          <FeaturesImg
            src={BOShoppersProfileImg}
            style={{
              position: "absolute",
              right: 44,
              bottom: 0,
              height: 360,
              border: `2px solid ${theme.colorsPalette.black.light}`,
            }}
          />
        </FeaturesIconsContainer>
      </FeaturesSubsectionContainer>
    </FeaturesContainer>
  );
}
