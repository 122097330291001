// Only translated to original languages the supported ones.
export const LANGUAGES: TLocale[] = [
  {
    code: "aa",
    name: "Afar",
    nativeName: "Afar",
  },
  {
    code: "ab",
    name: "Abkhazian",
    nativeName: "Abkhazian",
  },
  {
    code: "ae",
    name: "Avestan",
    nativeName: "Avestan",
  },
  {
    code: "af",
    name: "Afrikaans",
    nativeName: "Afrikaans",
  },
  {
    code: "af-ZA",
    name: "Afrikaans (South Africa)",
    nativeName: "Afrikaans (South Africa)",
  },
  {
    code: "ak",
    name: "Akan",
    nativeName: "Akan",
  },
  {
    code: "am",
    name: "Amharic",
    nativeName: "Amharic",
  },
  {
    code: "an",
    name: "Aragonese",
    nativeName: "Aragonese",
  },
  {
    code: "ar",
    name: "Arabic",
    nativeName: "Arabic",
  },
  {
    code: "ar-AE",
    name: "Arabic (U.A.E.)",
    nativeName: "Arabic (U.A.E.)",
  },
  {
    code: "ar-BH",
    name: "Arabic (Bahrain)",
    nativeName: "Arabic (Bahrain)",
  },
  {
    code: "ar-DZ",
    name: "Arabic (Algeria)",
    nativeName: "Arabic (Algeria)",
  },
  {
    code: "ar-EG",
    name: "Arabic (Egypt)",
    nativeName: "Arabic (Egypt)",
  },
  {
    code: "ar-IQ",
    name: "Arabic (Iraq)",
    nativeName: "Arabic (Iraq)",
  },
  {
    code: "ar-JO",
    name: "Arabic (Jordan)",
    nativeName: "Arabic (Jordan)",
  },
  {
    code: "ar-KW",
    name: "Arabic (Kuwait)",
    nativeName: "Arabic (Kuwait)",
  },
  {
    code: "ar-LB",
    name: "Arabic (Lebanon)",
    nativeName: "Arabic (Lebanon)",
  },
  {
    code: "ar-LY",
    name: "Arabic (Libya)",
    nativeName: "Arabic (Libya)",
  },
  {
    code: "ar-MA",
    name: "Arabic (Morocco)",
    nativeName: "Arabic (Morocco)",
  },
  {
    code: "ar-OM",
    name: "Arabic (Oman)",
    nativeName: "Arabic (Oman)",
  },
  {
    code: "ar-QA",
    name: "Arabic (Qatar)",
    nativeName: "Arabic (Qatar)",
  },
  {
    code: "ar-SA",
    name: "Arabic (Saudi Arabia)",
    nativeName: "Arabic (Saudi Arabia)",
  },
  {
    code: "ar-SY",
    name: "Arabic (Syria)",
    nativeName: "Arabic (Syria)",
  },
  {
    code: "ar-TN",
    name: "Arabic (Tunisia)",
    nativeName: "Arabic (Tunisia)",
  },
  {
    code: "ar-YE",
    name: "Arabic (Yemen)",
    nativeName: "Arabic (Yemen)",
  },
  {
    code: "as",
    name: "Assamese",
    nativeName: "Assamese",
  },
  {
    code: "av",
    name: "Avaric",
    nativeName: "Avaric",
  },
  {
    code: "ay",
    name: "Aymara",
    nativeName: "Aymara",
  },
  {
    code: "az",
    name: "Azerbaijani",
    nativeName: "Azerbaijani",
  },
  {
    code: "ba",
    name: "Bashkir",
    nativeName: "Bashkir",
  },
  {
    code: "be",
    name: "Belarusian",
    nativeName: "Belarusian",
  },
  {
    code: "be-BY",
    name: "Belarusian (Belarus)",
    nativeName: "Belarusian (Belarus)",
  },
  {
    code: "bg",
    name: "Bulgarian",
    nativeName: "Bulgarian",
  },
  {
    code: "bg-BG",
    name: "Bulgarian (Bulgaria)",
    nativeName: "Bulgarian (Bulgaria)",
  },
  {
    code: "bh",
    name: "Bihari languages",
    nativeName: "Bihari languages",
  },
  {
    code: "bi",
    name: "Bislama",
    nativeName: "Bislama",
  },
  {
    code: "bm",
    name: "Bambara",
    nativeName: "Bambara",
  },
  {
    code: "bn",
    name: "Bengali",
    nativeName: "Bengali",
  },
  {
    code: "bo",
    name: "Tibetan",
    nativeName: "Tibetan",
  },
  {
    code: "br",
    name: "Breton",
    nativeName: "Breton",
  },
  {
    code: "bs",
    name: "Bosnian",
    nativeName: "Bosnian",
  },
  {
    code: "ca",
    name: "Catalan (Valencia)",
    nativeName: "Catalan (Valencia)",
  },
  {
    code: "ca-ES",
    name: "Catalan (Spain)",
    nativeName: "Catalan (Spain)",
  },
  {
    code: "ce",
    name: "Chechen",
    nativeName: "Chechen",
  },
  {
    code: "ch",
    name: "Chamorro",
    nativeName: "Chamorro",
  },
  {
    code: "co",
    name: "Corsican",
    nativeName: "Corsican",
  },
  {
    code: "cr",
    name: "Cree",
    nativeName: "Cree",
  },
  {
    code: "cs",
    name: "Czech",
    nativeName: "Czech",
  },
  {
    code: "cs-CZ",
    name: "Czech (Czech Republic)",
    nativeName: "Czech (Czech Republic)",
  },
  {
    code: "cu",
    name: "Church Slavonic, Old Bulgarian, Old Church Slavonic",
    nativeName: "Church Slavonic, Old Bulgarian, Old Church Slavonic",
  },
  {
    code: "cv",
    name: "Chuvash",
    nativeName: "Chuvash",
  },
  {
    code: "cy",
    name: "Welsh",
    nativeName: "Welsh",
  },
  {
    code: "da",
    name: "Danish",
    nativeName: "Danish",
  },
  {
    code: "da-DK",
    name: "Danish (Denmark)",
    nativeName: "Danish (Denmark)",
  },
  {
    code: "de",
    name: "Deutsch - DE",
    nativeName: "Deutsch - DE",
  },
  {
    code: "de-AT",
    name: "German (Austria)",
    nativeName: "German (Austria)",
  },
  {
    code: "de-CH",
    name: "German (Switzerland)",
    nativeName: "German (Switzerland)",
  },
  {
    code: "de-DE",
    name: "German (Germany)",
    nativeName: "German (Germany)",
  },
  {
    code: "de-LI",
    name: "German (Liechtenstein)",
    nativeName: "German (Liechtenstein)",
  },
  {
    code: "de-LU",
    name: "German (Luxembourg)",
    nativeName: "German (Luxembourg)",
  },
  {
    code: "div-MV",
    name: "Divehi (Maldives)",
    nativeName: "Divehi (Maldives)",
  },
  {
    code: "dv",
    name: "Divehi, Dhivehi, Maldivian",
    nativeName: "Divehi, Dhivehi, Maldivian",
  },
  {
    code: "dz",
    name: "Dzongkha",
    nativeName: "Dzongkha",
  },
  {
    code: "ee",
    name: "Ewe",
    nativeName: "Ewe",
  },
  {
    code: "el",
    name: "Greek (Modern)",
    nativeName: "Greek (Modern)",
  },
  {
    code: "el-GR",
    name: "Greek (Greece)",
    nativeName: "Greek (Greece)",
  },
  {
    code: "en",
    name: "English - EN",
    nativeName: "English - EN",
  },
  {
    code: "en-AU",
    name: "English (Australia)",
    nativeName: "English (Australia)",
  },
  {
    code: "en-BZ",
    name: "English (Belize)",
    nativeName: "English (Belize)",
  },
  {
    code: "en-CA",
    name: "English (Canada)",
    nativeName: "English (Canada)",
  },
  {
    code: "en-CB",
    name: "English (Caribbean)",
    nativeName: "English (Caribbean)",
  },
  {
    code: "en-GB",
    name: "English (United Kingdom)",
    nativeName: "English (United Kingdom)",
  },
  {
    code: "en-IE",
    name: "English (Ireland)",
    nativeName: "English (Ireland)",
  },
  {
    code: "en-JM",
    name: "English (Jamaica)",
    nativeName: "English (Jamaica)",
  },
  {
    code: "en-NZ",
    name: "English (New Zealand)",
    nativeName: "English (New Zealand)",
  },
  {
    code: "en-PH",
    name: "English (Republic of the Philippines)",
    nativeName: "English (Republic of the Philippines)",
  },
  {
    code: "en-TT",
    name: "English (Trinidad and Tobago)",
    nativeName: "English (Trinidad and Tobago)",
  },
  {
    code: "en-US",
    name: "English (United States)",
    nativeName: "English (United States)",
  },
  {
    code: "en-ZA",
    name: "English (South Africa)",
    nativeName: "English (South Africa)",
  },
  {
    code: "en-ZW",
    name: "English (Zimbabwe)",
    nativeName: "English (Zimbabwe)",
  },
  {
    code: "eo",
    name: "Esperanto",
    nativeName: "Esperanto",
  },
  {
    code: "es",
    name: "Español - ES",
    nativeName: "Español - ES",
  },
  {
    code: "es-AR",
    name: "Español (Argentina",
    nativeName: "Español (Argentina",
  },
  {
    code: "es-BO",
    name: "Español (Bolivia)",
    nativeName: "Español (Bolivia)",
  },
  {
    code: "es-CL",
    name: "Español (Chile)",
    nativeName: "Español (Chile)",
  },
  {
    code: "es-CO",
    name: "Español (Colombia)",
    nativeName: "Español (Colombia)",
  },
  {
    code: "es-CR",
    name: "Español (Costa Rica)",
    nativeName: "Español (Costa Rica)",
  },
  {
    code: "es-DO",
    name: "Español (Repub. Dominicana)",
    nativeName: "Español (Repub. Dominicana)",
  },
  {
    code: "es-EC",
    name: "Español (Ecuador)",
    nativeName: "Español (Ecuador)",
  },
  {
    code: "es-ES",
    name: "Español (España)",
    nativeName: "Español (España)",
  },
  {
    code: "es-GT",
    name: "Español (Guatemala)",
    nativeName: "Español (Guatemala)",
  },
  {
    code: "es-HN",
    name: "Español (Honduras)",
    nativeName: "Español (Honduras)",
  },
  {
    code: "es-MX",
    name: "Español (Mexico)",
    nativeName: "Español (Mexico)",
  },
  {
    code: "es-NI",
    name: "Español (Nicaragua)",
    nativeName: "Español (Nicaragua)",
  },
  {
    code: "es-PA",
    name: "Español (Panamá)",
    nativeName: "Español (Panamá)",
  },
  {
    code: "es-PE",
    name: "Español (Perú)",
    nativeName: "Español (Perú)",
  },
  {
    code: "es-PR",
    name: "Español (Puerto Rico)",
    nativeName: "Español (Puerto Rico)",
  },
  {
    code: "es-PY",
    name: "Español (Paraguay)",
    nativeName: "Español (Paraguay)",
  },
  {
    code: "es-SV",
    name: "Español (El Salvador)",
    nativeName: "Español (El Salvador)",
  },
  {
    code: "es-UY",
    name: "Español (Uruguay)",
    nativeName: "Español (Uruguay)",
  },
  {
    code: "es-VE",
    name: "Español (Venezuela)",
    nativeName: "Español (Venezuela)",
  },
  {
    code: "et",
    name: "Estonian",
    nativeName: "Estonian",
  },
  {
    code: "et-EE",
    name: "Estonian (Estonia)",
    nativeName: "Estonian (Estonia)",
  },
  {
    code: "eu",
    name: "Basque",
    nativeName: "Basque",
  },
  {
    code: "eu-ES",
    name: "Basque (Spain)",
    nativeName: "Basque (Spain)",
  },
  {
    code: "fa",
    name: "Farsi",
    nativeName: "Farsi",
  },
  {
    code: "fa-IR",
    name: "Farsi (Iran)",
    nativeName: "Farsi (Iran)",
  },
  {
    code: "ff",
    name: "Fulah",
    nativeName: "Fulah",
  },
  {
    code: "fi",
    name: "Finnish",
    nativeName: "Finnish",
  },
  {
    code: "fi-FI",
    name: "Finnish (Finland)",
    nativeName: "Finnish (Finland)",
  },
  {
    code: "fj",
    name: "Fijian",
    nativeName: "Fijian",
  },
  {
    code: "fo",
    name: "Faroese",
    nativeName: "Faroese",
  },
  {
    code: "fo-FO",
    name: "Faroese (Faroe Islands)",
    nativeName: "Faroese (Faroe Islands)",
  },
  {
    code: "fr",
    name: "French",
    nativeName: "French",
  },
  {
    code: "fr-BE",
    name: "French (Belgium)",
    nativeName: "French (Belgium)",
  },
  {
    code: "fr-CA",
    name: "French (Canada)",
    nativeName: "French (Canada)",
  },
  {
    code: "fr-CH",
    name: "French (Switzerland)",
    nativeName: "French (Switzerland)",
  },
  {
    code: "fr-FR",
    name: "French (France)",
    nativeName: "French (France)",
  },
  {
    code: "fr-LU",
    name: "French (Luxembourg)",
    nativeName: "French (Luxembourg)",
  },
  {
    code: "fr-MC",
    name: "French (Principality of Monaco)",
    nativeName: "French (Principality of Monaco)",
  },
  {
    code: "fy",
    name: "Western Frisian",
    nativeName: "Western Frisian",
  },
  {
    code: "ga",
    name: "Irish",
    nativeName: "Irish",
  },
  {
    code: "gd",
    name: "Gaelic, Scottish Gaelic",
    nativeName: "Gaelic, Scottish Gaelic",
  },
  {
    code: "gl",
    name: "Galician",
    nativeName: "Galician",
  },
  {
    code: "gl-ES",
    name: "Galician (Spain)",
    nativeName: "Galician (Spain)",
  },
  {
    code: "gn",
    name: "Guarani",
    nativeName: "Guarani",
  },
  {
    code: "gu",
    name: "Gujarati",
    nativeName: "Gujarati",
  },
  {
    code: "gu-IN",
    name: "Gujarati (India)",
    nativeName: "Gujarati (India)",
  },
  {
    code: "gv",
    name: "Manx",
    nativeName: "Manx",
  },
  {
    code: "ha",
    name: "Hausa",
    nativeName: "Hausa",
  },
  {
    code: "he",
    name: "Hebrew",
    nativeName: "Hebrew",
  },
  {
    code: "he-IL",
    name: "Hebrew (Israel)",
    nativeName: "Hebrew (Israel)",
  },
  {
    code: "hi",
    name: "Hindi",
    nativeName: "Hindi",
  },
  {
    code: "hi-IN",
    name: "Hindi (India)",
    nativeName: "Hindi (India)",
  },
  {
    code: "ho",
    name: "Hiri Motu",
    nativeName: "Hiri Motu",
  },
  {
    code: "hr",
    name: "Croatian",
    nativeName: "Croatian",
  },
  {
    code: "hr-HR",
    name: "Croatian (Croatia)",
    nativeName: "Croatian (Croatia)",
  },
  {
    code: "ht",
    name: "Haitian, Haitian Creole",
    nativeName: "Haitian, Haitian Creole",
  },
  {
    code: "hu",
    name: "Hungarian",
    nativeName: "Hungarian",
  },
  {
    code: "hu-HU",
    name: "Hungarian (Hungary)",
    nativeName: "Hungarian (Hungary)",
  },
  {
    code: "hy",
    name: "Armenian",
    nativeName: "Armenian",
  },
  {
    code: "hy-AM",
    name: "Armenian (Armenia)",
    nativeName: "Armenian (Armenia)",
  },
  {
    code: "hz",
    name: "Herero",
    nativeName: "Herero",
  },
  {
    code: "ia",
    name: "Interlingua (International Auxiliary Language Association)",
    nativeName: "Interlingua (International Auxiliary Language Association)",
  },
  {
    code: "id",
    name: "Indonesian",
    nativeName: "Indonesian",
  },
  {
    code: "id-ID",
    name: "Indonesian (Indonesia)",
    nativeName: "Indonesian (Indonesia)",
  },
  {
    code: "ie",
    name: "Interlingue",
    nativeName: "Interlingue",
  },
  {
    code: "ig",
    name: "Igbo",
    nativeName: "Igbo",
  },
  {
    code: "ii",
    name: "Nuosu, Sichuan Yi",
    nativeName: "Nuosu, Sichuan Yi",
  },
  {
    code: "ik",
    name: "Inupiaq",
    nativeName: "Inupiaq",
  },
  {
    code: "io",
    name: "Ido",
    nativeName: "Ido",
  },
  {
    code: "is",
    name: "Icelandic",
    nativeName: "Icelandic",
  },
  {
    code: "is-IS",
    name: "Icelandic (Iceland)",
    nativeName: "Icelandic (Iceland)",
  },
  {
    code: "it",
    name: "Italian",
    nativeName: "Italian",
  },
  {
    code: "it-CH",
    name: "Italian (Switzerland)",
    nativeName: "Italian (Switzerland)",
  },
  {
    code: "it-IT",
    name: "Italian (Italy)",
    nativeName: "Italian (Italy)",
  },
  {
    code: "iu",
    name: "Inuktitut",
    nativeName: "Inuktitut",
  },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "Japanese",
  },
  {
    code: "ja-JP",
    name: "Japanese (Japan)",
    nativeName: "Japanese (Japan)",
  },
  {
    code: "jv",
    name: "Javanese",
    nativeName: "Javanese",
  },
  {
    code: "ka",
    name: "Georgian",
    nativeName: "Georgian",
  },
  {
    code: "ka-GE",
    name: "Georgian (Georgia)",
    nativeName: "Georgian (Georgia)",
  },
  {
    code: "kg",
    name: "Kongo",
    nativeName: "Kongo",
  },
  {
    code: "ki",
    name: "Gikuyu, Kikuyu",
    nativeName: "Gikuyu, Kikuyu",
  },
  {
    code: "kj",
    name: "Kwanyama, Kuanyama",
    nativeName: "Kwanyama, Kuanyama",
  },
  {
    code: "kk",
    name: "Kazakh",
    nativeName: "Kazakh",
  },
  {
    code: "kk-KZ",
    name: "Kazakh (Kazakhstan)",
    nativeName: "Kazakh (Kazakhstan)",
  },
  {
    code: "kl",
    name: "Greenlandic, Kalaallisut",
    nativeName: "Greenlandic, Kalaallisut",
  },
  {
    code: "km",
    name: "Central Khmer",
    nativeName: "Central Khmer",
  },
  {
    code: "kn",
    name: "Kannada",
    nativeName: "Kannada",
  },
  {
    code: "kn-IN",
    name: "Kannada (India)",
    nativeName: "Kannada (India)",
  },
  {
    code: "ko",
    name: "Korean",
    nativeName: "Korean",
  },
  {
    code: "ko-KR",
    name: "Korean (Korea)",
    nativeName: "Korean (Korea)",
  },
  {
    code: "kr",
    name: "Kanuri",
    nativeName: "Kanuri",
  },
  {
    code: "ks",
    name: "Kashmiri",
    nativeName: "Kashmiri",
  },
  {
    code: "ku",
    name: "Kurdish",
    nativeName: "Kurdish",
  },
  {
    code: "kv",
    name: "Komi",
    nativeName: "Komi",
  },
  {
    code: "kw",
    name: "Cornish",
    nativeName: "Cornish",
  },
  {
    code: "ky",
    name: "Kyrgyz",
    nativeName: "Kyrgyz",
  },
  {
    code: "ky-KZ",
    name: "Kyrgyz (Kyrgyzstan)",
    nativeName: "Kyrgyz (Kyrgyzstan)",
  },
  {
    code: "la",
    name: "Latin",
    nativeName: "Latin",
  },
  {
    code: "lb",
    name: "Letzeburgesch, Luxembourgish",
    nativeName: "Letzeburgesch, Luxembourgish",
  },
  {
    code: "lg",
    name: "Ganda",
    nativeName: "Ganda",
  },
  {
    code: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgish, Limburgan, Limburger",
  },
  {
    code: "ln",
    name: "Lingala",
    nativeName: "Lingala",
  },
  {
    code: "lo",
    name: "Lao",
    nativeName: "Lao",
  },
  {
    code: "lt",
    name: "Lithuanian",
    nativeName: "Lithuanian",
  },
  {
    code: "lt-LT",
    name: "Lithuanian (Lithuania)",
    nativeName: "Lithuanian (Lithuania)",
  },
  {
    code: "lu",
    name: "Luba-Katanga",
    nativeName: "Luba-Katanga",
  },
  {
    code: "lv",
    name: "Latvian",
    nativeName: "Latvian",
  },
  {
    code: "lv-LV",
    name: "Latvian (Latvia)",
    nativeName: "Latvian (Latvia)",
  },
  {
    code: "mg",
    name: "Malagasy",
    nativeName: "Malagasy",
  },
  {
    code: "mh",
    name: "Marshallese",
    nativeName: "Marshallese",
  },
  {
    code: "mi",
    name: "Maori",
    nativeName: "Maori",
  },
  {
    code: "mk",
    name: "Macedonian",
    nativeName: "Macedonian",
  },
  {
    code: "mk-MK",
    name: "Macedonian (Former Yugoslav Republic of Macedonia)",
    nativeName: "Macedonian (Former Yugoslav Republic of Macedonia)",
  },
  {
    code: "ml",
    name: "Malayalam",
    nativeName: "Malayalam",
  },
  {
    code: "mn",
    name: "Mongolian",
    nativeName: "Mongolian",
  },
  {
    code: "mn-MN",
    name: "Mongolian (Mongolia)",
    nativeName: "Mongolian (Mongolia)",
  },
  {
    code: "mr",
    name: "Marathi",
    nativeName: "Marathi",
  },
  {
    code: "mr-IN",
    name: "Marathi (India)",
    nativeName: "Marathi (India)",
  },
  {
    code: "ms",
    name: "Malay",
    nativeName: "Malay",
  },
  {
    code: "ms-BN",
    name: "Malay (Brunei Darussalam)",
    nativeName: "Malay (Brunei Darussalam)",
  },
  {
    code: "ms-MY",
    name: "Malay (Malaysia)",
    nativeName: "Malay (Malaysia)",
  },
  {
    code: "mt",
    name: "Maltese",
    nativeName: "Maltese",
  },
  {
    code: "my",
    name: "Burmese",
    nativeName: "Burmese",
  },
  {
    code: "na",
    name: "Nauru",
    nativeName: "Nauru",
  },
  {
    code: "nb",
    name: "Norwegian Bokmål",
    nativeName: "Norwegian Bokmål",
  },
  {
    code: "nb-NO",
    name: "Norwegian (Bokmål) (Norway)",
    nativeName: "Norwegian (Bokmål) (Norway)",
  },
  {
    code: "nd",
    name: "Northern Ndebele",
    nativeName: "Northern Ndebele",
  },
  {
    code: "ne",
    name: "Nepali",
    nativeName: "Nepali",
  },
  {
    code: "ng",
    name: "Ndonga",
    nativeName: "Ndonga",
  },
  {
    code: "nl",
    name: "Dutch, Flemish",
    nativeName: "Dutch, Flemish",
  },
  {
    code: "nl-BE",
    name: "Dutch (Belgium)",
    nativeName: "Dutch (Belgium)",
  },
  {
    code: "nl-NL",
    name: "Dutch (Netherlands)",
    nativeName: "Dutch (Netherlands)",
  },
  {
    code: "nn",
    name: "Norwegian Nynorsk",
    nativeName: "Norwegian Nynorsk",
  },
  {
    code: "nn-NO",
    name: "Norwegian Nynorsk (Norway)",
    nativeName: "Norwegian Nynorsk (Norway)",
  },
  {
    code: "no",
    name: "Norwegian",
    nativeName: "Norwegian",
  },
  {
    code: "nr",
    name: "South Ndebele",
    nativeName: "South Ndebele",
  },
  {
    code: "nv",
    name: "Navajo, Navaho",
    nativeName: "Navajo, Navaho",
  },
  {
    code: "ny",
    name: "Chichewa, Chewa, Nyanja",
    nativeName: "Chichewa, Chewa, Nyanja",
  },
  {
    code: "oc",
    name: "Occitan (post 1500)",
    nativeName: "Occitan (post 1500)",
  },
  {
    code: "oj",
    name: "Ojibwa",
    nativeName: "Ojibwa",
  },
  {
    code: "om",
    name: "Oromo",
    nativeName: "Oromo",
  },
  {
    code: "or",
    name: "Oriya",
    nativeName: "Oriya",
  },
  {
    code: "os",
    name: "Ossetian, Ossetic",
    nativeName: "Ossetian, Ossetic",
  },
  {
    code: "pa",
    name: "Panjabi, Punjabi",
    nativeName: "Panjabi, Punjabi",
  },
  {
    code: "pa-IN",
    name: "Punjabi (India)",
    nativeName: "Punjabi (India)",
  },
  {
    code: "pi",
    name: "Pali",
    nativeName: "Pali",
  },
  {
    code: "pl",
    name: "Polish",
    nativeName: "Polish",
  },
  {
    code: "pl-PL",
    name: "Polish (Poland)",
    nativeName: "Polish (Poland)",
  },
  {
    code: "ps",
    name: "Pashto, Pushto",
    nativeName: "Pashto, Pushto",
  },
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Portuguese",
  },
  {
    code: "pt-BR",
    name: "Portuguese (Brazil)",
    nativeName: "Portuguese (Brazil)",
  },
  {
    code: "pt-PT",
    name: "Portuguese (Portugal)",
    nativeName: "Portuguese (Portugal)",
  },
  {
    code: "qu",
    name: "Quechua",
    nativeName: "Quechua",
  },
  {
    code: "rm",
    name: "Romansh",
    nativeName: "Romansh",
  },
  {
    code: "rn",
    name: "Rundi",
    nativeName: "Rundi",
  },
  {
    code: "ro",
    name: "Romanian",
    nativeName: "Romanian",
  },
  {
    code: "ro-RO",
    name: "Romanian (Romania)",
    nativeName: "Romanian (Romania)",
  },
  {
    code: "ru",
    name: "Russian",
    nativeName: "Russian",
  },
  {
    code: "ru-RU",
    name: "Russian (Russia)",
    nativeName: "Russian (Russia)",
  },
  {
    code: "rw",
    name: "Kinyarwanda",
    nativeName: "Kinyarwanda",
  },
  {
    code: "sa",
    name: "Sanskrit",
    nativeName: "Sanskrit",
  },
  {
    code: "sa-IN",
    name: "Sanskrit (India)",
    nativeName: "Sanskrit (India)",
  },
  {
    code: "sc",
    name: "Sardinian",
    nativeName: "Sardinian",
  },
  {
    code: "sd",
    name: "Sindhi",
    nativeName: "Sindhi",
  },
  {
    code: "se",
    name: "Northern Sami",
    nativeName: "Northern Sami",
  },
  {
    code: "sg",
    name: "Sango",
    nativeName: "Sango",
  },
  {
    code: "si",
    name: "Sinhala, Sinhalese",
    nativeName: "Sinhala, Sinhalese",
  },
  {
    code: "sk",
    name: "Slovak",
    nativeName: "Slovak",
  },
  {
    code: "sk-SK",
    name: "Slovak (Slovakia)",
    nativeName: "Slovak (Slovakia)",
  },
  {
    code: "sl",
    name: "Slovenian",
    nativeName: "Slovenian",
  },
  {
    code: "sl-SI",
    name: "Slovenian (Slovenia)",
    nativeName: "Slovenian (Slovenia)",
  },
  {
    code: "sm",
    name: "Samoan",
    nativeName: "Samoan",
  },
  {
    code: "sn",
    name: "Shona",
    nativeName: "Shona",
  },
  {
    code: "so",
    name: "Somali",
    nativeName: "Somali",
  },
  {
    code: "sq",
    name: "Albanian",
    nativeName: "Albanian",
  },
  {
    code: "sq-AL",
    name: "Albanian (Albania)",
    nativeName: "Albanian (Albania)",
  },
  {
    code: "sr",
    name: "Serbian",
    nativeName: "Serbian",
  },
  {
    code: "ss",
    name: "Swati",
    nativeName: "Swati",
  },
  {
    code: "st",
    name: "Sotho, Southern",
    nativeName: "Sotho, Southern",
  },
  {
    code: "su",
    name: "Sundanese",
    nativeName: "Sundanese",
  },
  {
    code: "sv",
    name: "Swedish",
    nativeName: "Swedish",
  },
  {
    code: "sv-FI",
    name: "Swedish (Finland)",
    nativeName: "Swedish (Finland)",
  },
  {
    code: "sv-SE",
    name: "Swedish (Sweden)",
    nativeName: "Swedish (Sweden)",
  },
  {
    code: "sw",
    name: "Swahili",
    nativeName: "Swahili",
  },
  {
    code: "sw-KE",
    name: "Swahili (Kenya)",
    nativeName: "Swahili (Kenya)",
  },
  {
    code: "ta",
    name: "Tamil",
    nativeName: "Tamil",
  },
  {
    code: "ta-IN",
    name: "Tamil (India)",
    nativeName: "Tamil (India)",
  },
  {
    code: "te",
    name: "Telugu",
    nativeName: "Telugu",
  },
  {
    code: "te-IN",
    name: "Telugu (India)",
    nativeName: "Telugu (India)",
  },
  {
    code: "tg",
    name: "Tajik",
    nativeName: "Tajik",
  },
  {
    code: "th",
    name: "Thai",
    nativeName: "Thai",
  },
  {
    code: "th-TH",
    name: "Thai (Thailand)",
    nativeName: "Thai (Thailand)",
  },
  {
    code: "ti",
    name: "Tigrinya",
    nativeName: "Tigrinya",
  },
  {
    code: "tk",
    name: "Turkmen",
    nativeName: "Turkmen",
  },
  {
    code: "tl",
    name: "Tagalog",
    nativeName: "Tagalog",
  },
  {
    code: "tn",
    name: "Tswana",
    nativeName: "Tswana",
  },
  {
    code: "to",
    name: "Tonga (Tonga Islands)",
    nativeName: "Tonga (Tonga Islands)",
  },
  {
    code: "tr",
    name: "Turkish",
    nativeName: "Turkish",
  },
  {
    code: "tr-TR",
    name: "Turkish (Turkey)",
    nativeName: "Turkish (Turkey)",
  },
  {
    code: "ts",
    name: "Tsonga",
    nativeName: "Tsonga",
  },
  {
    code: "tt",
    name: "Tatar",
    nativeName: "Tatar",
  },
  {
    code: "tt-RU",
    name: "Tatar (Russia)",
    nativeName: "Tatar (Russia)",
  },
  {
    code: "tw",
    name: "Twi",
    nativeName: "Twi",
  },
  {
    code: "ty",
    name: "Tahitian",
    nativeName: "Tahitian",
  },
  {
    code: "ug",
    name: "Uighur, Uyghur",
    nativeName: "Uighur, Uyghur",
  },
  {
    code: "uk",
    name: "Ukrainian",
    nativeName: "Ukrainian",
  },
  {
    code: "uk-UA",
    name: "Ukrainian (Ukraine)",
    nativeName: "Ukrainian (Ukraine)",
  },
  {
    code: "ur",
    name: "Urdu",
    nativeName: "Urdu",
  },
  {
    code: "ur-PK",
    name: "Urdu (Islamic Republic of Pakistan)",
    nativeName: "Urdu (Islamic Republic of Pakistan)",
  },
  {
    code: "uz",
    name: "Uzbek",
    nativeName: "Uzbek",
  },
  {
    code: "ve",
    name: "Venda",
    nativeName: "Venda",
  },
  {
    code: "vi",
    name: "Vietnamese",
    nativeName: "Vietnamese",
  },
  {
    code: "vi-VN",
    name: "Vietnamese (Vietnam)",
    nativeName: "Vietnamese (Vietnam)",
  },
  {
    code: "vo",
    name: "Volap_k",
    nativeName: "Volap_k",
  },
  {
    code: "wa",
    name: "Walloon",
    nativeName: "Walloon",
  },
  {
    code: "wo",
    name: "Wolof",
    nativeName: "Wolof",
  },
  {
    code: "xh",
    name: "Xhosa",
    nativeName: "Xhosa",
  },
  {
    code: "yi",
    name: "Yiddish",
    nativeName: "Yiddish",
  },
  {
    code: "yo",
    name: "Yoruba",
    nativeName: "Yoruba",
  },
  {
    code: "za",
    name: "Zhuang, Chuang",
    nativeName: "Zhuang, Chuang",
  },
  {
    code: "zh",
    name: "Chinese",
    nativeName: "Chinese",
  },
  {
    code: "zh-CHS",
    name: "Chinese (S)",
    nativeName: "Chinese (S)",
  },
  {
    code: "zh-CHT",
    name: "Chinese (Traditional)",
    nativeName: "Chinese (Traditional)",
  },
  {
    code: "zh-CN",
    name: "Chinese (PRC)",
    nativeName: "Chinese (PRC)",
  },
  {
    code: "zh-HK",
    name: "Chinese (Hong Kong)",
    nativeName: "Chinese (Hong Kong)",
  },
  {
    code: "zh-MO",
    name: "Chinese (Macau)",
    nativeName: "Chinese (Macau)",
  },
  {
    code: "zh-SG",
    name: "Chinese (Singapore)",
    nativeName: "Chinese (Singapore)",
  },
  {
    code: "zh-TW",
    name: "Chinese (T)",
    nativeName: "Chinese (T)",
  },
  {
    code: "zu",
    name: "Zulu",
    nativeName: "Zulu",
  },
];

export const SUPPORTED_LANGUAGES_CODES: TSupportedLanguageCode[] = [
  "en",
  "es",
  "de",
];

export const LANGUAGES_GROUPS: Record<TSupportedLanguageCode, TLanguageCode[]> =
  {
    en: [
      "en",
      "en-AU",
      "en-BZ",
      "en-CA",
      "en-CB",
      "en-GB",
      "en-IE",
      "en-JM",
      "en-NZ",
      "en-PH",
      "en-TT",
      "en-US",
      "en-ZA",
      "en-ZW",
    ],
    es: [
      "es",
      "es-AR",
      "es-BO",
      "es-CL",
      "es-CO",
      "es-CR",
      "es-DO",
      "es-EC",
      "es-ES",
      "es-GT",
      "es-HN",
      "es-MX",
      "es-NI",
      "es-PA",
      "es-PE",
      "es-PR",
      "es-PY",
      "es-SV",
      "es-UY",
      "es-VE",
    ],
    de: ["de", "de-DE"],
  };

export const SUPPORTED_LANGUAGES = LANGUAGES.filter((language) =>
  SUPPORTED_LANGUAGES_CODES.includes(language.code as TSupportedLanguageCode)
) as TLocale<TSupportedLanguageCode>[];
