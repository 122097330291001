export default function BeautifulIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 363.000000 362.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#FFFB00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,362.000000) scale(0.100000,-0.100000)"
        fill="url(#gradient)"
        stroke="none"
      >
        <path
          d="M1445 3580 c-367 -31 -592 -78 -750 -157 -280 -140 -515 -422 -594
-713 -56 -204 -91 -557 -91 -906 0 -490 52 -864 151 -1082 103 -228 310 -439
543 -552 152 -74 266 -101 571 -137 190 -22 785 -25 970 -5 202 22 383 52 473
78 347 101 627 364 758 711 63 167 104 499 111 898 7 419 -6 484 -108 552 -57
38 -157 44 -216 15 -44 -23 -99 -80 -114 -120 -6 -15 -11 -183 -13 -386 -2
-305 -6 -386 -24 -521 -28 -204 -43 -261 -92 -360 -70 -143 -173 -245 -315
-315 -100 -50 -154 -63 -363 -92 -149 -20 -208 -23 -542 -23 -334 0 -393 3
-542 23 -209 29 -263 42 -363 92 -171 84 -300 234 -359 415 -45 140 -79 487
-79 805 0 318 34 665 79 805 59 181 188 331 359 415 98 49 156 64 355 91 126
17 225 22 525 26 330 5 374 7 405 23 168 86 159 330 -15 407 -35 15 -77 18
-330 19 -159 1 -335 -2 -390 -6z"
        />
        <path
          d="M3090 3572 c-12 -9 -47 -67 -78 -127 l-57 -110 -109 -56 c-124 -64
-146 -85 -146 -138 0 -49 30 -76 145 -134 50 -25 97 -53 106 -63 9 -11 37 -60
62 -110 51 -101 83 -134 132 -134 49 0 72 25 134 146 l56 109 105 52 c117 57
150 88 150 137 0 50 -25 72 -146 135 l-109 56 -56 109 c-31 61 -67 118 -79
128 -28 23 -81 23 -110 0z"
        />
        <path
          d="M1105 2345 c-22 -8 -59 -34 -82 -58 -135 -143 -36 -371 162 -371 129
0 219 90 219 219 0 158 -149 263 -299 210z"
        />
        <path
          d="M2342 2347 c-89 -33 -146 -115 -146 -212 0 -129 90 -219 219 -219
202 0 299 233 156 375 -64 65 -148 85 -229 56z"
        />
        <path
          d="M1359 1602 c-77 -40 -114 -102 -114 -191 0 -87 21 -128 109 -216 79
-79 173 -133 272 -159 82 -21 266 -21 348 0 147 38 309 162 362 279 51 112 12
231 -95 287 -103 54 -197 33 -288 -65 -98 -105 -208 -105 -306 0 -91 98 -185
119 -288 65z"
        />
      </g>
    </svg>
  );
}
