export const DE_TRANSLATIONS: TLocaleTranslation = {
  intlDateTime: "{{val, datetime}}",
  navbar: {
    aboutLinkText: "Über uns",
    plansLinkText: "Plans",
    changelogLinkText: "Changelog",
    signInButtonText: "Anmelden",
  },
  home: {
    intro: {
      title1: "Die Zukunft",
      title2: "ist lokal",
      subtitle:
        "Eine Plattform, die die florierende Gemeinschaft lokaler Unternehmer auf der ganzen Welt verbindet",
      goToPlatformBtnText: "Zur Plattform",
    },
    companies: {
      title: "Stärkung unabhängiger Schöpfer",
      andMoreText: "und mehr",
    },
    features: {
      businessManagement: {
        title: "Alle Ihre Geschäfts pipelines an einem einzigen Ort",
        descriptionText:
          "Leistungsstarke und intuitive Tools, mit denen Sie Ihr gesamtes Unternehmen mühelos verwalten können. Produktinventar, Auftragserfüllung, Kundenverwaltung, Versand und Lieferung, erweiterte Analysen und mehr",
      },
      socialNetwork: {
        title: "Lassen Sie sich von neuen Leuten entdecken",
        descriptionText:
          "Veröffentlichen Sie Produkte und exklusive Inhalte auf unserer Plattform und erreichen Sie Tausende von neuen Menschen, die an einzigartigen und unabhängigen Projekten interessiert sind",
      },
      collaborative: {
        title: "Arbeiten Sie mit anderen Erstellern zusammen",
        descriptionText:
          "Greifen Sie auf unser lokales Handelsnetzwerk zu und stärken Sie Ihren Umsatz mit mehr Erstellern als Ihnen. Cross-Selling- und Kooperationskampagnen, Wissensaustauschprogramme, Multi-Brand-Stores und mehr",
      },
      ownVirtualSpace: {
        title: "Eigener virtueller Speicher der Domäne",
        descriptionText:
          "Ihre Follower können sich registrieren und exklusive Inhalte Ihrer Marke genießen, intelligente Empfehlungen erhalten und über einen frischen und intuitiven virtuellen Shop einkaufen",
      },
      analytics: {
        title: "Echte Einblicke in Ihr Publikum",
        descriptionText:
          "Erfahren Sie mehr über Ihre Follower, über ihre Profile und Vorlieben. Erreichen Sie sie mit dem Recht, was sie wollen",
      },
    },
    competitiveAdvantages: {
      title1: "Wo sich unabhängige Marken",
      title2: "mit unabhängigen",
      title3: "Menschen verbinden",
      localAndExclusive: {
        title: "Lokale und exklusive Marken",
        descriptionText:
          "Grafito wird nur von lokalen Schöpfern erstellt. Bei uns finden Sie Projekte mit einzigartigen Vorschlägen und mit hochkreativen & exklusiven Produkten",
      },
      communityOriented: {
        title: "Gemeinschaftsorientiert",
        descriptionText:
          "Wir sind eine Gemeinschaft, die an die lokalen Werte glaubt, sie unterstützt und dazu beiträgt, dass sie wächst und wettbewerbsfähig ist",
      },
      beautifulAndEasyToUse: {
        title: "Schön und einfach zu bedienen",
        descriptionText:
          "Intuitive, robuste und sorgfältig gestaltete Funktionen, um das Potenzial Ihres Projekts freizusetzen und Ihren Followern ein frisches Einkaufserlebnis zu bieten",
      },
      fairPrice: {
        title: "Fairer Preis",
        descriptionText:
          "Flexible und skalierbare Preise für die verschiedenen Phasen Ihres Projekts. Elastisches Preismodell, bei dem wir gewinnen, wenn Sie gewinnen",
      },
      personalizedGuidance: {
        title: "Persönliche Führung & Begleitung",
        descriptionText:
          "Wir gehen Kompromisse mit unseren Schöpfern ein und gehen Schritt für Schritt mit ihnen, wenn es darum geht, ihre Projekte auszubauen. Wir stellen Ihnen unsere Erfahrung zur Verfügung, um Sie durch alle Phasen Ihres Projekts zu begleiten",
      },
      multiSalesChannels: {
        title: "Multi-Verkaufskanäle gleichzeitig",
        descriptionText1:
          "Wenn Sie Teil unserer Community sind, können Sie in unserer",
        descriptionText2: "Galerie",
        descriptionText3:
          "entdeckt werden und haben Ihren virtuellen Store mit eigener Domain. Gleichzeitig und ohne zusätzliche Konfigurationen",
      },
    },
    testimonials: {
      title: "Wird von Unternehmern verwendet",
      t1: {
        actorNameText: "Bárbara Cantó",
        actorRoleText: "Gründerin von Woolie Beanies",
        testimonialText:
          "Grafito ist eine super gute Lösung für Unternehmer, die ihre Geschäfte schnell und effizient online aufbauen und sich mit anderen Entwicklern verbinden möchten. Das Team dahinter ist super cool und aufmerksam, um alle möglichen Probleme zu lösen. Auch sehr offen dafür, ihren Service ständig zu verbessern. 100% empfehlenswert",
      },
      t2: {
        actorNameText: "Isidora de la Sotta",
        actorRoleText: "Gründerin von Etnik und Minga Kids",
        testimonialText:
          "Mit Grafito konnte ich mein Geschäft viel besser organisieren. Es hat meine Arbeit so viel einfacher und klarer gemacht. Jetzt kann ich meine Verkäufe, Produkte und Kunden viel effizienter verwalten und mehr Zeit damit verbringen, Entscheidungen zu treffen",
      },
      t3: {
        actorNameText: "Andrea Errazuriz",
        actorRoleText: "Gründerin von Lambra",
        testimonialText:
          "Unglaubliche Lösung, um meinen Kunden mein Projekt auf schöne, einfache und frische Weise zu zeigen. Es hilft mir sehr, eine Ordnung in meinem Verkauf aufrechtzuerhalten. Ich bin null mit Technologie und es hat mich keine Mühe gekostet, sie zu benutzen! Sehr nette und professionelle Leute",
      },
      t4: {
        actorNameText: "Verónica Sutil",
        actorRoleText: "Gründerin von Ropa Verónica Sutil",
        testimonialText:
          "Mit Grafito konnte ich meine Marke ins Web bringen. Meine Follower können meine Produkte sehen und viel bequemer kaufen. Es hat definitiv meine Verkäufe und die Anzahl der Kunden verbessert! Es ist sehr einfach zu bedienen und das Onboarding war großartig",
      },
      t5: {
        actorNameText: "Reimundo Musri",
        actorRoleText: "Gründer von Reimundo A. Musri",
        testimonialText:
          "Grafito hat mir geholfen, mein Geschäft besser zu verstehen. Es verfügt über leistungsstarke Tools, die meine Arbeit viel einfacher gemacht haben und es mir ermöglicht haben, meine Kunden effektiver zu erreichen. Ich habe viele Künstler wie mich auf der Plattform entdeckt, sowie neue Follower, die sich für mein Projekt interessieren",
      },
      t6: {
        actorNameText: "Francisca Kegevic",
        actorRoleText: "Co-Gründerin von Hybrida",
        testimonialText:
          "Wir haben lange nach Optionen für E-Commerce gesucht und Grafito war sowieso die beste. Seine niedrigen Raten sind perfekt für Unternehmer und das Team dahinter ist großartig, mit einer unglaublichen Einstellung und Sorge, die in jeder Hinsicht zu 100 % zu empfehlen ist!",
      },
    },
    startToday: {
      title: "Starten Sie noch heute mit Grafito.app",
      subtitle:
        "Wir sind die bequemste Plattform für Unternehmer. Modernste Technologie zum Wachsen Ihres Projekts",
      startButtonText: "Jetzt anfangen",
    },
  },
  about: {
    ourPhilosophy: {
      title1: "Von Machern",
      title2: "Für Macher",
      descriptionText:
        "Heutzutage unterstützen und bevorzugen die Menschen lokale Unternehmen mehr denn je gegenüber den großen Unternehmen von immer. Es gibt Millionen da draußen, die zusammen einen Billionen-Dollar-Markt darstellen. Unser Ziel ist es, diese lokalen Unternehmen zu stärken und sie auf die nächste Stufe zu bringen",
    },
    team: {
      title: "Die Mannschaft",
      subtitle:
        "Wir sind ein multidisziplinäres Team. Ingenieure, Vermarkter, Artdirektoren, audiovisuelle Kommunikatoren, Wirtschaftsprüfer. Sehr unterschiedliche Talente, mit einer gemeinsamen Vision: eine Plattform, die lokalen Schöpfern hilft, ihre Träume zu verwirklichen",
    },
  },
  changelog: {
    title: "Changelog",
    subtitle: "Updates und Verbesserungen unserer Plattform",
    improvementsTitle: "Verbesserungen",
    "v1.5.0": {
      title: "Ein-Klick-Zahlungen",
      descriptionText1:
        "Jetzt können Ihre Follower mit nur einem Klick in Ihrem Virtual Store bezahlen! Keine lästige Formularerfüllung mehr für den Kauf Ihrer Produkte. Einfach einkaufen, in den Warenkorb legen und mit einem Klick bezahlen",
      descriptionText2:
        "Wir haben ein ausgeklügeltes und sicheres Wallet-System für Ihre Käufer in Grafito integriert. Jetzt können sie ihre Zahlungsmethoden bei uns registrieren und beim Bezahlen einfach auswählen, welche sie verwenden möchten",
    },
    "v1.4.0": {
      title: "Internationalisierung des Virtual Store",
      descriptionText1:
        "Wir haben die Multi-Language Virtual Store-Funktion aktiviert!",
      descriptionText2:
        "Jetzt können Sie Ihren Virtual Store in folgenden Sprachen anzeigen: Englisch (EN), Spanisch (ES) oder Deutsch (DE). Auf diese Weise können Sie Ihre Verkäufe und Ihr Publikum auf den Rest der Welt ausdehnen!",
      descriptionText3:
        "Jedes Mal, wenn Ihre Follower Ihre virtuellen Geschäfte betreten, erkennen wir ihre Sprache (basierend auf ihrer Lokalisierung und den Spracheinstellungen auf ihrem Gerät) und übersetzen Ihren virtuellen Laden in diese Sprache. Wir geben ihnen auch die Möglichkeit, die Sprache einfach zu wechseln (in der Navigationsleiste wird eine Flagge angezeigt).",
    },
    "v1.3.0": {
      title: "Käufer suchen, sortieren und paginieren",
      descriptionText1:
        "Von Ihrem Backoffice aus ist es jetzt viel einfacher, Ihre Follower zu visualisieren. Wir haben die Such-, Sortier- und Paginierungsfunktion aktiviert, sodass Sie deutlicher sehen können, wie Ihre Follower mit Ihren Produkten und Inhalten interagieren",
      improvementsText1:
        "Wir haben auch unsere Allgemeinen Geschäftsbedingungen aktualisiert, um unseren Basisplan zu aktualisieren und ein monatliches festes Abonnement von €35 + 3% Provision pro Transaktion für unsere Ersteller in Betracht zu ziehen",
    },
    "v1.2.0": {
      title: "Produkte sortieren und filtern",
      descriptionText1:
        "Mit dieser Version von Grafito haben Ihre Follower Zugriff auf leistungsstarke Filter, um schneller und einfacher nach Ihren Produkten zu suchen",
      descriptionText2:
        "Sie können sie nach Name, Erstellungsdatum, Preis und mehr sortieren und sie auch basierend auf einer dynamischen Preisskala filtern und ob sie einen Rabatt erhalten haben oder nicht",
    },
    "v1.1.0": {
      title: "Google Analytics-Integration",
      descriptionText1:
        "Wir haben Ihren virtuellen Shop in Google Analytics integriert, damit Sie das Verhalten Ihrer Follower überwachen und bessere Einblicke in sie und die Nutzung Ihres Shops erhalten können",
      descriptionText2:
        "Sie finden es im Abschnitt -Apps- in Ihrem Backoffice, wo Sie nur Ihre Google Analytics -Tracking-ID- eingeben müssen und Ihr Geschäft wird mit dem Tool verbunden",
      descriptionText3:
        "Wir haben ein vollständiges Tutorial zur Integration des Tools in Grafito erstellt, damit Sie bei Null anfangen können, um Usability- und Interaktionsmetriken Ihrer Kunden zu erstellen",
    },
    "v1.0.0": {
      title: "Grafito-Galerie",
      descriptionText1:
        "Die erste Version von Grafito wurde mit dem Start unserer Galerie eingeweiht: Wo alle unsere Schöpfer an einem einzigen Ort sind. Die Gemeinde",
      descriptionText2: "Im Start unserer Galerie finden Sie:",
      li1: "Intelligente Empfehlungen von Erstellerprofilen, Kategorien und Produkten",
      li2: "Neue Schöpfer, Trends, Aktionen und Neuigkeiten",
      li3: "Suchen, filtern und sortieren Sie nach Produkten, Erstellern oder Kategorien",
      li4: "Kaufen Sie Produkte in den virtuellen Stores der Ersteller",
    },
    "v0.23.0": {
      title: "Shopping-Ankündigungsleiste",
      descriptionText1:
        "Jetzt können Sie Ihre Anzeigen, Werbeaktionen, neuen Produkte und alle Arten von Ankündigungen in einer vollständig anpassbaren oberen Leiste veröffentlichen, die Ihren Kunden in Ihrem virtuellen Geschäft angezeigt werden kann",
      li1: "Verbessern Sie den Käuferauthentifizierungsprozess, um ein intuitiveres und schnelleres Erlebnis zu ermöglichen",
      li2: "Verbessern Sie die Zugänglichkeit des Virtual Store für Produktkategorien und Details. Korrigieren Sie einige Layoutdefinitionen, um den Zugriff auf mehrere Geräte zu erleichtern",
    },
    "v0.22.0": {
      title: "Universelles Käuferkonto",
      descriptionText1:
        "Ab heute haben Ihre Kunden ein eigenes Profil in Grafito, in dem sie ihre persönlichen Daten (Namen, E-Mail-Adresse, Lieferadressen usw.) speichern, ihre Einkäufe überprüfen und ihr Profil konfigurieren können",
      descriptionText2:
        "Sie können sich in ihre virtuellen Geschäfte einloggen und ein viel flüssigeres Einkaufserlebnis haben",
      descriptionText3:
        "Eine wichtige Funktion wurde hier hinzugefügt und ist, dass jeder Käufer ein universelles Konto in Grafito haben wird. Sie können alle unsere virtuellen Shops für Ersteller betreten und durch unsere Galerie navigieren, ohne sich mehrmals anmelden zu müssen, und behalten immer das gleiche Profil bei!",
    },
    "v0.21.0": {
      title: "Auftragsbericht",
      descriptionText1:
        "Jetzt können Sie von Ihrem Backoffice aus jederzeit einen vollständigen Bestellbericht Ihrer Verkäufe herunterladen. Wählen Sie Filter, Metriken und Analysen aus, um sie als Excel-Datei herunterzuladen",
    },
    "v0.20.0": {
      title: "Rich-Text-Editor für Produktbeschreibungen",
      descriptionText1:
        "Fügen Sie Titel, Untertitel, Bilder, Listen, Links, Emojis und vieles mehr in Ihre Produktbeschreibungen ein",
      descriptionText2:
        "Wir haben ein leistungsstarkes Rich-Content-Editor-Tool für alle Ihre Produktbeschreibungen hinzugefügt. Kompatibel mit Mobilgeräten, Desktops und allen Geräten",
      li1: "Korrekturen, wie Rabattgutscheine auf die Gesamtbestellsummen angewendet werden",
      li2: "Korrekturen beim Scrollen im Virtual Store bei Abschnittsänderungen. Verbessern Sie die Benutzeroberfläche und das Erlebnis beim Scrollen von Produkten",
    },
    "v0.19.0": {
      title: "Produkthändler",
      descriptionText1:
        "Jetzt können Sie in Grafito Produkte von anderen Händlern in Ihrem virtuellen Geschäft hinzufügen. Produkten können Kategorien und Händler zugeordnet sein",
    },
    "v0.18.0": {
      title: "Produkte suchen und paginieren",
      descriptionText1:
        "In dieser Version haben wir zwei grundlegende neue Funktionen in unsere Apps aufgenommen",
      li1: "Suchen Sie nach beliebigen Produktattributen sowohl in Ihrem Backoffice als auch in Ihrem virtuellen Geschäft. Verwenden Sie Name, Kategorie, Beschreibung und Varianten und Sie sehen alle Übereinstimmungen in einer einfach zu navigierenden Liste",
      li2: "Große Produktlisten werden jetzt in Blöcken (oder Teilen) angezeigt, sodass sie viel schneller und organisierter geladen werden",
    },
    "v0.17.0": {
      title: "Produktbilder sortieren",
      descriptionText1:
        "Durch diese neue Funktion können Sie alle Bilder Ihrer Produkte nach Belieben bestellen",
      descriptionText2:
        "Wir haben eine extrem einfache und coole Oberfläche entwickelt, damit Sie die Reihenfolge, in der Ihre Kunden Ihre Produkte sehen, nach Ihren Wünschen konfigurieren können",
    },
    "v0.16.0": {
      title: "Bulk-Upload von Produkten",
      descriptionText1:
        "In dieser neuen Version der Plattform haben wir die Funktionalität hinzugefügt, verschiedene Arten von Produkten gleichzeitig massiv hochzuladen",
      descriptionText2:
        "Dies ist jetzt möglich, indem Sie eine CSV-Datei aus dem Backoffice der Plattform hochladen, in der Sie alle Produkte, die Sie hinzufügen möchten, in Zeilen auflisten können, unter anderem mit Name, Kategorie, Preis, Bestand, Beschreibung. Sie können die Beispieltabelle auch aus dem Backoffice herunterladen",
    },
    "v0.15.0": {
      title: "Benutzerdefinierte Diashows",
      descriptionText1:
        "Wir haben die Möglichkeit hinzugefügt, dass Sie der Startseite Ihres virtuellen Geschäfts personalisierte Diashows hinzufügen können. Das heißt, ein Abschnitt verschiedener Bilder mit Titeln, Untertiteln und Links, die sich in bestimmten Abständen ändern",
      descriptionText2:
        "Speziell, um Ihre Werbeaktionen, neue Produkte oder jede Art von Neuigkeiten für Ihre Follower zu veröffentlichen",
      descriptionText3:
        "Es ist möglich, die Höhe der Diashow, die Deckkraft des schwarzen Schleiers, der verwendet wird, um den Text hervorzuheben, und die Geschwindigkeit, mit der jede Folie zur nächsten wechselt, zu konfigurieren",
      improvementsText1:
        "Wir haben die Art und Weise verbessert, in der die Zusammenfassung Ihrer Verkäufe, Kundenentwicklung, Bestellungen und Verkäufe nach Standort auf dem Dashboard der Plattform angezeigt wird",
      improvementsText2:
        "Jetzt können Sie die Informationen einfacher anzeigen und Zeitfilter auf die Statistiken anwenden",
    },
    "v0.14.0": {
      title: "Abholoption im Geschäft",
      descriptionText1:
        "Jetzt können Ihre Kunden wählen, ob sie eine Lieferung nach Hause oder eine Abholung im Geschäft wünschen, wenn sie Ihre Produkte in Ihrem virtuellen Geschäft kaufen",
      descriptionText2:
        "Diese neue Modalität kann einfach über das Backoffice der Plattform aktiviert oder deaktiviert werden. Alle Kundenbestellungen tragen jetzt die Angabe, ob sie zu Ihnen nach Hause geliefert werden sollen oder der Kunde sie bei Ihnen abholt",
    },
    "v0.13.0": {
      title: "Rabatt Coupons",
      descriptionText1:
        "Durch diese neue Funktion können Sie Ihren Followern Coupons geben, die sie beim Kauf in Ihrem virtuellen Geschäft aktivieren können, um personalisierte Rabatte auf ihre Einkäufe zu erhalten",
      descriptionText2:
        "Sie können so viele Coupons generieren, wie Sie möchten, ihnen bestimmte Rabatte hinzufügen (feste Rabattbeträge oder Prozentsätze des Gesamtkaufs), die Anzahl der Verwendungen begrenzen und ein Ablaufdatum festlegen. Alles auf einfache und intuitive Weise von Ihrem Backoffice aus",
      improvementsText1:
        "Wir haben ein Problem im Zusammenhang mit dem Nichtlesen von Lieferzonen und -orten behoben, wenn Kunden beim Kauf den Lieferort nicht angegeben haben",
    },
    "v0.12.0": {
      title: "Blogs und Galerien in Ihrem virtuellen Geschäft",
      descriptionText1:
        "Mit dieser neuen Funktion ist es möglich, personalisierte Blog- oder Bildergalerieseiten zu erstellen, auf die Ihre Kunden von Ihrem virtuellen Geschäft aus zugreifen können",
      descriptionText2:
        "Wir nennen Blogs eine Seite, auf der Sie jede Art von Artikel schreiben und unter anderem Bilder, Titel, Untertitel, Zitate hinzufügen können. Andererseits können Sie über Galerien Fotos (unter anderem Veranstaltungen, Neuigkeiten, vorgestellte Produkte) in einem schönen und attraktiven Format veröffentlichen, damit Ihre Follower sie in Ihrem virtuellen Geschäft sehen können",
      improvementsText1:
        "Wir haben ein Problem behoben, das bei der Anzeige des Gesamtbestands eines Produkts in der Produktliste festgestellt wurde, wenn es aus mehreren Varianten mit jeweils unterschiedlichen Lagerbeständen besteht",
    },
    "v0.11.0": {
      title: "Produktvolumen",
      descriptionText1:
        "Wir haben die Option hinzugefügt, die Höhe, Länge, Breite und das Gewicht jedes Ihrer Produkte anzugeben, damit Sie eine detailliertere Produktdefinition und eine viel genauere Methode zur Berechnung Ihrer Versandkosten haben",
      li1: "Wir haben eine Reihe von Verbesserungen an der Leistung beim Laden von Aufträgen Ihrer Kunden vorgenommen, sodass sie jetzt geladen werden und Sie sie viel schneller verwalten können",
      li2: "Beim Aktualisieren von Navigationslinks wurde ein Problem erkannt, und dass die Änderungen nicht automatisch im Backoffice angezeigt wurden, sondern dass die App neu geladen werden musste, um sie zu sehen, wurde behoben",
    },
    "v0.10.0": {
      title: "Positionierung von Navigationslinks",
      descriptionText1:
        "Wir haben die Möglichkeit hinzugefügt, die Navigationslinks Ihres virtuellen Geschäfts in der von Ihnen gewünschten Reihenfolge zu positionieren",
    },
    "v0.9.0": {
      title: "Navigationslinks",
      descriptionText1:
        "In dieser neuen Version von Grafito haben wir die Möglichkeit integriert, die Navigationsmenüs Ihres virtuellen Geschäfts selbst zu erstellen. Auf diese Weise können Sie die Art und Weise, wie Ihre Follower Ihren virtuellen Laden besuchen, besser anpassen, sodass Sie Ihre Kategorien und Unterkategorien besser klassifizieren, bestimmte Inhalte Ihrer Marke hervorheben und das Erlebnis verbessern können, in dem Ihre Kunden Ihren Laden durchsuchen",
    },
    "v0.8.0": {
      title: "Bilder auf benutzerdefinierten Seiten",
      descriptionText1:
        "Wir bieten die Möglichkeit, Bilder in die personalisierten Seiten Ihres virtuellen Geschäfts einzufügen. Sie können Ihrem Inhalt mehrere Bilder hinzufügen, um ihn für Ihre Follower nahrhafter zu machen!",
    },
    "v0.7.0": {
      title: "Ausrichtung der Navigationsleiste des virtuellen Geschäfts",
      descriptionText1:
        "In dieser Version von Grafito enthalten wir die Möglichkeit, dass Sie die Navigationsleiste so konfigurieren können, dass sie horizontal oder vertikal angezeigt wird, um eine minimalistische und explizitere Art der Anzeige Ihrer Kategorielinks zu bieten",
      improvementsText1:
        "Wir haben mehrere Verbesserungen bei der Handhabung von Rabatten nach Produkt und nach Variante vorgenommen",
    },
    "v0.6.0": {
      title: "Automatische Signatur der Nutzungsbedingungen",
      descriptionText1:
        "Wir haben alle unsere Nutzungsbedingungen der Plattform in einem einzigen Dokument zusammengefasst, das digital in Ihrem Backoffice akzeptiert werden kann. Dadurch können unsere Marken viel schneller bei uns integriert werden und werden von unnötiger Dokumentation befreit",
      improvementsText1:
        "Wir haben den Prozess der Erstellung oder Aktualisierung von Produkten und deren Varianten viel einfacher und intuitiver gestaltet. Wir haben einige unnötige Prozesse auf der Benutzerseite entfernt und den Workflow viel schneller gemacht",
    },
    "v0.5.0": {
      title: "Preis und SKU pro Produktvariante",
      descriptionText1:
        "Jetzt kann jede Produktvariante ihren eigenen Preis und ihre eigene SKU tragen, unabhängig davon, zu welchem Produkt sie gehört",
      descriptionText2:
        "Dadurch erhalten Sie viel mehr Flexibilität bei der Erstellung und Klassifizierung Ihrer Produkte sowie eine bessere Benutzererfahrung für Ihre Follower bei der Auswahl Ihrer Produkte",
      li1: "Wir definieren als automatisches Bestellkriterium, um Ihre Follower aufzulisten, das Datum ihrer letzten Interaktion mit Ihrer Marke (in diesem Fall Kauf)",
      li2: "Wir fügen die optionalen Felder für Telefonnummer und Bürger-ID für Ihre Follower hinzu",
      li3: "Wir haben einige visuelle Probleme bei der Anzeige der Produktkategorieliste auf kleinen Geräten behoben",
    },
    "v0.4.0": {
      title: "Befehlsstandsverwaltung",
      descriptionText1:
        "Wir haben eine sehr wichtige Funktion für unsere Marken integriert: die Möglichkeit, den Lebenszyklus Ihrer Bestellungen zu verwalten und Ihre Kunden in Echtzeit über ihre Änderungen zu informieren",
      descriptionText2:
        "Das bedeutet, dass Sie jetzt den Status Ihrer Bestellungen verwalten können (erhalten, in Vorbereitung, im Versand, versandt, storniert) und wir kümmern uns darum, dass Ihre Kunden über jede Bewegung auf dem Laufenden gehalten werden",
    },
    "v0.3.0": {
      title: "UI/UX-Redesign",
      descriptionText1:
        "Wir haben wichtige Verbesserungen im Design der Plattform vorgenommen, sowohl für Ihr Backoffice als auch für Ihr virtuelles Geschäft. Wir haben ein völlig neues Interface-Design eingeführt, das Ihnen und Ihren Followern ein völlig intuitives, frisches und angenehmes Nutzungserlebnis bietet",
    },
    "v0.2.0": {
      title: "Personalisierte Notizen für die Kasse",
      descriptionText1:
        "In dieser Version führen wir die Möglichkeit ein, dass Sie Notizen für Ihre Kunden an der Kasse ihres Einkaufs hinzufügen können, unabhängig von der Ankündigung, die Sie machen möchten",
      descriptionText2:
        "Fügen Sie einfach den Text aus Ihrem Backoffice hinzu und Ihre Kunden sehen ihn, wenn sie ihren Einkauf bezahlen",
      li1: "Wir haben einen Fehler im Zusammenhang mit der Handhabung von Status (aktiviert oder deaktiviert) von Produktkategorien behoben. Zuvor wurde die Aktion zum Ändern des Status nicht im virtuellen Geschäft widergespiegelt, mit den integrierten Verbesserungen wurde die Funktionalität wieder normal",
      li2: "Wir haben den Produkterstellungsprozess verbessert, sodass die Plattform die Liste der Produkte automatisch aktualisiert, nachdem sie erstellt wurden",
    },
    "v0.1.0": {
      title: "Launch unserer Plattform",
      descriptionText1:
        "Grafito.app stellt seine erste Version der Öffentlichkeit vor. In dieser ersten Version enthalten wir mehrere grundlegende Funktionen für unsere Marken, unter denen sich hervorheben: Produkt- und Bestandsverwaltung, Bestell- und Zahlungsverwaltung, Kundenverwaltung, grundlegende Verkaufsstatistiken und ein zu 100 % verwaltbarer virtueller Laden, damit Ihre Follower Ihre Marke kennenlernen können und kaufen Sie Ihre Produkte",
      descriptionText2:
        "Die Plattform besteht aus zwei Anwendungen, einem Backoffice und einem Online-Shop mit einer personalisierten Domain für jede unserer Marken (mit integriertem Warenkorb und Zahlungsprozess)",
    },
  },
  footer: {
    aboutLinkText: "Über uns",
    plansLinkText: "Plans",
    changelogLinkText: "Changelog",
    copyrightText: "© {{copyrightYear}} Grafito.app - Weltweit entwickelt",
  },
  localesSwitcher: {
    title: "Spracheinstellungen",
    description:
      "Wählen dir die Sprache, die dir für unsere Website bevorzugen.",
  },
};
