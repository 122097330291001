import { theme } from "kosmos-ui/lib";

function LinkedInIcon({
  color = "currentColor",
  height = "80px",
  style,
  variant = "outline",
}: TIconProps) {
  switch (variant) {
    case "gradient":
      return (
        <svg viewBox="0 0 24 24" height={height} style={style}>
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop stopColor={theme.colorsPalette.blue.dark} offset="0%" />
              <stop
                stopColor={theme.colorsPalette.cyan.default}
                offset="100%"
              />
            </linearGradient>
          </defs>

          <path
            fill="url(#gradient)"
            d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"
          />
          <path fill="url(#gradient)" d="m.396 7.977h4.976v16.023h-4.976z" />
          <path
            fill="url(#gradient)"
            d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"
          />
        </svg>
      );

    case "outline":
      return (
        <svg viewBox="-28 0 512 512" height={height} style={style}>
          <path
            fill={color}
            d="m104.359375 151.625h-86.3125c-9.675781 0-17.546875 7.871094-17.546875 17.546875v325.285156c0 9.671875 7.871094 17.542969 17.546875 17.542969h86.3125c9.671875 0 17.542969-7.871094 17.542969-17.542969v-325.285156c0-9.675781-7.871094-17.546875-17.542969-17.546875zm-12.5 330.332031h-61.316406v-300.289062h61.316406zm0 0"
          />
          <path
            fill={color}
            d="m61.203125 0c-33.75 0-61.203125 27.453125-61.203125 61.199219 0 33.75 27.453125 61.203125 61.203125 61.203125 33.742187 0 61.199219-27.453125 61.199219-61.203125 0-33.746094-27.453125-61.199219-61.199219-61.199219zm0 92.359375c-17.183594 0-31.160156-13.976563-31.160156-31.160156 0-17.179688 13.976562-31.15625 31.160156-31.15625 17.179687 0 31.15625 13.976562 31.15625 31.15625 0 17.179687-13.976563 31.160156-31.15625 31.160156zm0 0"
          />
          <path
            fill={color}
            d="m401.046875 189.761719c-20.632813-17.222657-46.863281-26.433594-73.832031-25.933594-16.75.308594-33.117188 4.347656-47.949219 11.660156v-7.101562c0-9.253907-7.503906-16.757813-16.757813-16.757813h-87.882812c-9.253906 0-16.757812 7.503906-16.757812 16.757813v326.855469c0 9.257812 7.503906 16.757812 16.757812 16.757812h89.617188c8.296874 0 15.023437-6.726562 15.023437-15.019531v-194.878907c0-16.683593 13.054687-30.800781 29.726563-31.296874 17.328124-.511719 31.585937 13.429687 31.585937 30.644531v195.84375c0 8.121093 6.585937 14.707031 14.707031 14.707031h86.128906c8.121094 0 14.707032-6.585938 14.707032-14.707031v-189.886719c0-45.546875-20.074219-88.429688-55.074219-117.644531zm25.03125 292.195312h-55.453125v-180.507812c0-33.46875-27.230469-60.699219-60.703125-60.699219-33.46875 0-60.699219 27.230469-60.699219 60.699219v180.507812h-61.3125v-300.289062h61.3125v21.832031h.019532c-.003907 3.539062 1.226562 7.09375 3.757812 9.957031 5.496094 6.210938 14.992188 6.789063 21.203125 1.289063 14.800781-13.101563 33.824219-20.515625 53.566406-20.882813 19.761719-.375 38.941407 6.367188 54.027344 18.960938 28.144531 23.492187 44.28125 57.964843 44.28125 94.582031zm0 0"
          />
        </svg>
      );

    case "fill":
    default:
      return null;
  }
}

export default LinkedInIcon;
