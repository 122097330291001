import { Card, theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import Typography from "app/components/Typography/Typography";
import { CheckIcon } from "app/assets/icons";

import { PLANS } from "./Plans.constants";
import {
  PlansContainer,
  PlanFeatureContainer,
  PlansCardsContainer,
  PlanIconContainer,
} from "./Plans.styles";

export default function PlansSection() {
  const { mobile } = useLayout();

  return (
    <PlansContainer>
      <Typography as="h1" variant="title2" style={{ textAlign: "center" }}>
        Meet our plans.
      </Typography>

      <Typography as="h2" variant="paragraph1" style={{ textAlign: "center" }}>
        Designed for the different stages of your project. Elastic pricing
        model, where if you win, we win.
      </Typography>

      <PlansCardsContainer>
        {PLANS.map((plan, index) => (
          <Card
            style={{
              padding: mobile ? "32px 24px" : "32px 52px",
              alignItems: mobile ? "center" : "unset",
              backgroundColor: "#1C1D1F",
            }}
            key={index}
          >
            <PlanIconContainer>{plan.icon}</PlanIconContainer>

            <Typography as="h1" variant="title3" style={{ marginBottom: 4 }}>
              {plan.name}
            </Typography>

            <Typography
              as="h2"
              variant="paragraph2"
              style={{
                marginBottom: 4,
                color: theme.colorsPalette.blue.default,
              }}
            >
              {plan.price}
            </Typography>

            {plan.features.map((feature, index) => {
              return (
                <PlanFeatureContainer key={index}>
                  <CheckIcon
                    height="16px"
                    style={{ marginRight: 8 }}
                    color={
                      feature.isNew
                        ? theme.colorsPalette.blue.default
                        : theme.colorsPalette.gray.dark
                    }
                  />

                  <Typography
                    as="p"
                    variant="paragraph3"
                    style={{
                      fontSize: 14,
                      color: feature.isNew
                        ? theme.colorsPalette.white.default
                        : "#8f9ab7",
                    }}
                  >
                    {feature.name}
                  </Typography>
                </PlanFeatureContainer>
              );
            })}
          </Card>
        ))}
      </PlansCardsContainer>

      <Typography as="i" variant="paragraph2">
        All plans include a 3% of transaction commission.
      </Typography>
    </PlansContainer>
  );
}
