function PaperPlaneIcon({ height = "675px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 807 675" style={style}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fillRule="nonzero">
          <path
            d="M780.165775,1.22798459 L11.2687767,290.780309 C4.00383435,297.060293 0.371363189,303.931731 0.371363189,311.394624 C0.371363189,318.857516 4.00383435,325.850124 11.2687767,332.372448 L204.850549,395.485082 L279.158738,623.197193 C281.252835,629.142346 284.935943,633.427811 290.20806,636.053589 C295.480177,638.679368 302.808154,638.344749 312.191991,635.049734 C382.67602,569.830513 420.411175,537.053239 425.397458,536.717912 C430.38374,536.382586 497.429357,580.896672 626.534308,670.260172 C634.224527,674.042351 641.154989,674.987896 647.325695,673.096806 C653.4964,671.205717 657.817622,667.152059 660.289361,660.935831 L807,22.1138943 C807,14.0324824 804.719759,8.23036653 800.159276,4.70754658 C795.598793,1.18472663 788.934293,0.0248726368 780.165775,1.22798459 Z"
            id="Primary"
            fill="#0083f5"
          ></path>
          <path
            d="M346.772879,448.557869 C346.772879,448.557869 329.247123,511.504574 294.19561,637.397983 C290.357649,636.247328 287.296033,634.600507 285.010764,632.457518 C282.725495,630.31453 280.760932,627.178651 279.117076,623.049882 C229.606058,471.340015 204.850549,395.485082 204.850549,395.485082 L650.073963,113.413805 C650.073963,113.413805 661.82195,110.854282 667.291806,117.403307 C670.938377,121.769323 671.510427,128.050143 669.007957,136.245767 L346.772879,448.557869 Z"
            id="Secondary"
            fill="#5cb3ff"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PaperPlaneIcon;
