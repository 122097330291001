import { theme } from "kosmos-ui/lib";

export default function TetrahedronIcon({
  height = "80px",
  style,
}: TIconProps) {
  return (
    <svg height={height} style={style} viewBox="0 0 100 100">
      <defs>
        <linearGradient id="gradient">
          <stop stopColor={theme.colorsPalette.blue.dark} offset="0%" />
          <stop stopColor={theme.colorsPalette.cyan.default} offset="100%" />
        </linearGradient>
      </defs>

      <path
        fill="url(#gradient)"
        d="M96.187,73.923c-0.053-0.096-0.109-0.191-0.181-0.275L42.835,2.751c-0.14-0.194-0.321-0.49-0.539-0.621   c0,0,0-0.13-0.002-0.13c0,0,0,0-0.001,0c-0.062,0-0.127,0.056-0.194,0.027c-0.406-0.18-0.844-0.167-1.236-0.058   C40.86,1.97,40.86,2,40.86,2c-0.001,0-0.001,0-0.003,0c-0.092,0-0.18,0.009-0.265,0.047c0,0,0-0.047-0.002-0.047h-0.001   c-0.32,0-0.597,0.486-0.784,0.812c-0.023,0.035-0.042,0.118-0.062,0.16L4.289,73.901c-0.058,0.109-0.104,0.236-0.138,0.354   c-0.076,0.261-0.09,0.53-0.047,0.788c0.041,0.262,0.139,0.519,0.296,0.749c0.069,0.099,0.148,0.195,0.239,0.28l22.133,22.27   C27.035,98.614,27.385,99,27.787,99h0.002h0.001c0.018,0,0.036,0,0.055,0h0.002c0.002,0,0.002,0,0.002,0   c0.072,0,0.145-0.117,0.215-0.117c0.21,0,0.415-0.101,0.604-0.171L95.12,76.527c0.102-0.029,0.201-0.088,0.296-0.137   c0.194-0.103,0.368-0.246,0.513-0.401c0.177-0.197,0.304-0.431,0.381-0.675c0.078-0.251,0.106-0.521,0.071-0.789   C96.353,74.32,96.289,74.113,96.187,73.923z M26.784,93.108L8.09,74.41l29.912-59.828L26.784,93.108z M30.267,94.302L42.54,8.384   l49.094,65.46L30.267,94.302z"
      />
    </svg>
  );
}
