export const ES_TRANSLATIONS: TLocaleTranslation = {
  intlDateTime: "{{val, datetime}}",
  navbar: {
    aboutLinkText: "Nosotros",
    plansLinkText: "Planes",
    changelogLinkText: "Changelog",
    signInButtonText: "Entrar",
  },
  home: {
    intro: {
      title1: "El futuro",
      title2: "es local",
      subtitle:
        "Una plataforma que conecta a la emergente comunidad de creadores locales en todo el mundo",
      goToPlatformBtnText: "Ir a la plataforma",
    },
    companies: {
      title: "Potenciando creadores independientes",
      andMoreText: "y más",
    },
    features: {
      businessManagement: {
        title: "Todos tus procesos en un solo lugar",
        descriptionText:
          "Herramientas potentes e intuitivas que te ayudarán a administrar todo tu proyecto fácilmente. Inventario de productos, ejecución de pedidos, gestión de clientes, delivery y entregas, analítica avanzada y más",
      },
      socialNetwork: {
        title: "Se descubierto por nuevos seguidores",
        descriptionText:
          "Publica productos y contenido exclusivo en nuestra plataforma y llega a miles de nuevos seguidores interesados en proyectos únicos e independientes",
      },
      collaborative: {
        title: "Trabaja con más creadores",
        descriptionText:
          "Accede a nuestra red de comercio local y potencia tus ventas con más creadores como tú. Campañas de venta cruzada y colaborativas, programas de intercambio de conocimientos, tiendas multimarca y más",
      },
      ownVirtualSpace: {
        title: "Tienda Virtual con dominio propio",
        descriptionText:
          "Tus seguidores podrán registrarse y disfrutar de contenido exclusivo de tu marca, recibir recomendaciones inteligentes y comprar a través de una Tienda Virtual propia fresca e intuitiva",
      },
      analytics: {
        title: "Analítica avanzada de tu audiencia",
        descriptionText:
          "Conoce a tus seguidores, sus perfiles y sus preferencias. Llega a ellos con lo que realmente quieren",
      },
    },
    competitiveAdvantages: {
      title1: "Donde marcas independientes",
      title2: "conectan con",
      title3: "personas independientes",
      localAndExclusive: {
        title: "Marcas locales y exclusivas",
        descriptionText:
          "Grafito está compuesto únicamente por creadores locales. Con nosotros encontrarás proyectos con propuestas únicas y con productos altamente creativos y exclusivos",
      },
      communityOriented: {
        title: "Somos comunidad",
        descriptionText:
          "Creemos en el valor del producto local, lo apoyamos y contribuimos a que crezca y sea competitivo",
      },
      beautifulAndEasyToUse: {
        title: "Intuitivo y fácil de usar",
        descriptionText:
          "Funciones intuitivas, robustas y meticulosamente diseñadas para desbloquear el potencial de tu proyecto y dar a tus seguidores una nueva experiencia de compra",
      },
      fairPrice: {
        title: "Precio justo",
        descriptionText:
          "Precios pensados en las distintas etapas de tu proyecto. Modelo de cobro elástico, donde si tú ganas, nosotros ganamos",
      },
      personalizedGuidance: {
        title: "Guía y acompañamiento personalizado",
        descriptionText:
          "Estamos comprometidos con nuestras marcas, y las guiamos en el crecimiento de sus proyectos. Ponemos nuestra experiencia a tu disposición para guiarte en todas las etapas de tu proyecto",
      },
      multiSalesChannels: {
        title: "Multi-canales de venta, simultáneamente",
        descriptionText1:
          "Ser parte de nuestra comunidad te permitirá ser descubierto en nuestra",
        descriptionText2: "Galería",
        descriptionText3:
          "y tener tu Tienda Virtual con dominio propio. Simultáneamente y sin configuraciones adicionales",
      },
    },
    testimonials: {
      title: "Usado por emprendedores",
      t1: {
        actorNameText: "Bárbara Cantó",
        actorRoleText: "Fundadora Woolie Beanies",
        testimonialText:
          "Grafito es una solución súper buena para emprendedores que buscan montar sus proyectos online de manera rápida y eficiente, junto con conectarse con otros creadores. El equipo que hay detrás es super bacán y atento para solucionar todo tipo de problemas. También muy abiertos a mejorar constantemente sus servicios. 100% recomendados",
      },
      t2: {
        actorNameText: "Isidora de la Sotta",
        actorRoleText: "Fundadora Etnik y Minga Kids",
        testimonialText:
          "Con Grafito pude organizar mucho más mi negocio. Ha hecho mi trabajo mucho más fácil y eficiente. Ahora puedo administrar mis ventas, productos y clientes de manera mucho más eficiente y dedicar más tiempo a la toma de decisiones",
      },
      t3: {
        actorNameText: "Andrea Errazuriz",
        actorRoleText: "Fundadora Lambra",
        testimonialText:
          "Increíble solución para mostrar mi proyecto en su máximo potencial, de manera fácil y fresca a mis clientes. Me ayuda mucho a mantener un orden en mis ventas. Soy nula con la tecnología y no me ha costado nada usar las plataformas! Gente muy agradable y profesional",
      },
      t4: {
        actorNameText: "Verónica Sutil",
        actorRoleText: "Fundadora Ropa Verónica Sutil",
        testimonialText:
          "Con Grafito pude llevar mi marca a la web. Mis seguidores pueden ver mis productos y comprar mucho más cómodamente ¡Definitivamente mejoró mis ventas y número de clientes! Es muy fácil de usar y la incorporación fue excelente",
      },
      t5: {
        actorNameText: "Reimundo Musri",
        actorRoleText: "Fundador Reimundo A. Musri",
        testimonialText:
          "Grafito me ha ayudado a entender mejor mi negocio. Cuenta con potentes herramientas que me han facilitado mucho el trabajo y me han permitido llegar a mis clientes de manera más efectiva. He descubierto muchos artistas como yo en la plataforma, así como nuevos seguidores interesados en mi proyecto",
      },
      t6: {
        actorNameText: "Francisca Kegevic",
        actorRoleText: "Co-fundadora Hybrida",
        testimonialText:
          "Estuvimos buscando opciones llevar nuestra marca a la web por mucho tiempo y Grafito fue la mejor opción por lejos. Sus bajas tarifas son perfectas para emprendedores y el equipo que hay detrás es tremendo, con una disposición e inquietud increíble ¡100% recomendos en todos los sentidos!",
      },
    },
    startToday: {
      title: "Empieza hoy con Grafito.app",
      subtitle:
        "Somos la plataforma más conveniente para los emprendedores. Tecnología de punta para hacer crecer tu proyecto",
      startButtonText: "Empezar ahora",
    },
  },
  about: {
    ourPhilosophy: {
      title1: "Por creadores",
      title2: "Para creadores",
      descriptionText:
        "Ahora más que nunca, la gente apoya y prefiere los negocios locales a las grandes empresas de siempre. Hay miles de millones en el mundo, que colectivamente representan un mercado de billones de dólares. Nuestro objetivo es empoderar a estos creadores y llevarlos al siguiente nivel",
    },
    team: {
      title: "El equipo",
      subtitle:
        "Somos un equipo multidisciplinario. Ingenieros, publicistas, directores de arte, comunicadores audiovisuales, auditores. Talentos muy diferentes, con una visión común: una plataforma para potenciar a los creadores locales a hacer realidad sus sueños",
    },
  },
  changelog: {
    title: "Changelog",
    subtitle: "Cambios y mejoras a nuestra plataforma",
    improvementsTitle: "Mejoras",
    "v1.5.0": {
      title: "Compras con 1 solo click",
      descriptionText1:
        "¡Ahora tus seguidores pueden pagar en tu Tienda Virtual con un solo click! No más tediosos formularios para comprar tus productos. Simplemente vitrinea, agrega al carrito y paga con un click",
      descriptionText2:
        "Hemos integrado a Grafito un sistema de wallet muy sofisticado y seguro para sus clientes. Ahora pueden registrar sus métodos de pago con nosotros y cuando paguen, simplemente elegir cuál usar",
    },
    "v1.4.0": {
      title: "Internacionalización de Tienda Virtual",
      descriptionText1:
        "¡Hemos habilitado la funcionalidad Tienda Virtual Multi-idioma!",
      descriptionText2:
        "Ahora puedes hacer que tu Tienda Virtual aparezca en: Inglés (EN), Español (ES) o Alemán (DE). ¡De esta manera puedes expandir tus ventas y audiencia al resto del mundo!",
      descriptionText3:
        "Cada vez que sus seguidores visiten tu Tienda Virtual, detectaremos su idioma (según su localización y configuraciones de su dispositivo) y traduciremos tu tienda a ese idioma. También les damos la opción de cambiar de idioma fácilmente (se muestra una bandera en la barra de navegación)",
    },
    "v1.3.0": {
      title: "Búsqueda, orden y paginación de clientes",
      descriptionText1:
        "Desde tu Backoffice ahora es mucho más fácil visualizar a tus seguidores. Hemos habilitado la funcionalidad de búsqueda, orden y paginación, para que puedas ver cómo tus seguidores interactúan con sus productos y contenido",
      improvementsText1:
        "También actualizamos nuestros Términos y Condiciones respecto a nuestro plan base para considerar una suscripción fija mensual de 1 UF + 3% de comisión por transacción para nuestras marcas",
    },
    "v1.2.0": {
      title: "Orden y filtrado de productos",
      descriptionText1:
        "Con esta versión de Grafito, tus seguidores tendrán acceso a potentes filtros para buscar tus productos de forma mucho más rápida y sencilla",
      descriptionText2:
        "Podrán ordenarlos por nombre, fecha de creación, precio y más, y también filtrarlos en función de una escala de precios dinámica y si tienen o no descuento aplicado",
    },
    "v1.1.0": {
      title: "Integración con Google Analytics",
      descriptionText1:
        "Hemos integrado tu Tienda Virtual con Google Analytics para que puedas monitorear el comportamiento de tus seguidores y obtener mejores insights sobre ellos y el uso de tu tienda",
      descriptionText2:
        "Puedes encontrar la integración en la sección -Apps- de tu Backoffice, donde solo debes ingresar tu Google Analytics -Tracking ID- y tu tienda se conectará automáticamente a la herramienta",
      descriptionText3:
        "Hicimos un tutorial completo sobre cómo hacer la integración con Grafito, para que puedas comenzar desde cero construyendo métricas de usabilidad e interacción de tus clientes",
    },
    "v1.0.0": {
      title: "Galería Grafito",
      descriptionText1:
        "Inauguramos la primera versión de Grafito con el lanzamiento de nuestra Galería: Donde todos nuestros creadores están en un solo lugar. La comunidad",
      descriptionText2: "En esta versión encontrarás:",
      li1: "Recomendaciones inteligentes de perfiles, categorías y productos de creadores",
      li2: "Nuevos creadores, tendencias, promociones y novedades",
      li3: "Búsqueda, filtro y orden de cualquier producto, creador o categoría",
      li4: "Compra de productos en tiendas virtuales de creadores",
    },
    "v0.23.0": {
      title: "Barra de anuncios en Tienda Virtual",
      descriptionText1:
        "Ahora puedes publicar tus anuncios, promociones, nuevos productos y todo tipo de información en una barra superior totalmente personalizable que se puede mostrar en tu Tienda Virtual para tus clientes",
      li1: "Mejoras en el proceso de autenticación de tus clientes para que la experiencia sea más intuitiva y rápida",
      li2: "Mejoras en la accesibilidad de tu tienda virtual para categorías de productos y detalles. Corrijimos algunas definiciones de diseño para que sea más accesible en múltiples dispositivos",
    },
    "v0.22.0": {
      title: "Cuenta de usuario Universal",
      descriptionText1:
        "A partir de hoy, tus clientes tendrán su propio perfil en Grafito, donde podrán guardar su información personal (nombres, correo electrónico, direcciones de envío, entre otros), revisar sus compras y configurar su perfil",
      descriptionText2:
        "Podrán iniciar sesión en sus tiendas virtuales y tener una experiencia de compra mucho más fluida",
      descriptionText3:
        "Hemos agregado una funcionalidad muy importante, y es que cada persona tendrá una cuenta universal en Grafito. Pueden ingresar a todas las tiendas virtuales de nuestros creadores y navegar por nuestra Galería sin necesidad de iniciar sesión varias veces, ¡y siempre manteniendo el mismo perfil!",
    },
    "v0.21.0": {
      title: "Reporte de ventas",
      descriptionText1:
        "Ahora desde tu Backoffice podrás descargar en cualquier momento un completo informe de pedidos de tus ventas. Selecciona filtros, métricas y análisis para descargarlo como archivo de Excel",
    },
    "v0.20.0": {
      title: "Editor de texto enriquecido para descripciones de productos",
      descriptionText1:
        "Inserta títulos, subtítulos, imágenes, listas, enlaces, emojis y mucho más en las descripciones de tus productos",
      descriptionText2:
        "Hemos agregado una poderosa herramienta de edición de contenido enriquecido para todas las descripciones de tus productos. Compatible con dispositivos móviles, de escritorio y más",
      li1: "Correcciones sobre cómo se aplican los cupones de descuento en los montos totales de los pedidos",
      li2: "Correcciones en la navegación de tu tienda virtual (principalmente en los cambios de sección). Mejoramos la interfaz de usuario y la experiencia al desplazarse por los productos",
    },
    "v0.19.0": {
      title: "Múltiples marcas de productos",
      descriptionText1:
        "Ahora en Grafito podrás agregar productos de otros comercios en tu Tienda Virtual. Los productos pueden tener categorías y comercios exteriores asociados",
    },
    "v0.18.0": {
      title: "Búsqueda y paginación de productos",
      descriptionText1:
        "En esta versión, incluimos 2 funciones nuevas fundamentales para nuestra plataforma",
      li1: "Búsqueda por cualquier atributo de un producto tanto en tu backoffice como en tu tienda virtual. Busca por su nombre, categoría, descripción o variantes y verás todos los matches en una lista fácil de navegar",
      li2: "Las grandes listas de productos ahora se muestran en fragmentos (o partes), por lo que se cargan mucho más rápido y organizados",
    },
    "v0.17.0": {
      title: "Orden de imágenes de productos",
      descriptionText1:
        "A través de esta nueva funcionalidad, podrás ordenar todas las imágenes de tus productos como quieras",
      descriptionText2:
        "Desarrollamos una interfaz sumamente sencilla para que puedas configurar a tu gusto el orden en que tus clientes ven tus productos",
    },
    "v0.16.0": {
      title: "Carga masiva de productos",
      descriptionText1:
        "En esta nueva versión de la plataforma agregamos la funcionalidad de subir de forma masiva diferentes tipos de productos a la vez",
      descriptionText2:
        "Esto ahora es posible subiendo un archivo CSV desde el backoffice de la plataforma, dentro del cual puedes listar en filas todos los productos que deseas agregar, detallando su nombre, categoría, precio, stock, descripción, entre otros. También puedes descargar un template de planilla desde el backoffice",
    },
    "v0.15.0": {
      title: "Slideshows personalizadas",
      descriptionText1:
        "Hemos añadido la posibilidad de que puedas añadir Slideshows personalizados a la página de inicio de tu tienda virtual. Es decir, una sección de varias imágenes con títulos, subtítulos y enlaces que cambian cada cierto tiempo",
      descriptionText2:
        "Especial para publicar tus promociones, nuevos productos o cualquier tipo de noticia para tus seguidores",
      descriptionText3:
        "Es posible configurar la altura de cada Slideshow, la opacidad del velo negro que se utiliza para resaltar el texto y la velocidad con la que cada slide pasa a la siguiente",
      improvementsText1:
        "Hemos mejorado la forma en que se muestra el resumen de tus ventas, evolución de clientes, pedidos y ventas por ubicación en el dashboard de la plataforma",
      improvementsText2:
        "Ahora puedes ver la información más fácilmente y aplicar filtros de tiempo en las estadísticas",
    },
    "v0.14.0": {
      title: "Opción de retiro en tienda",
      descriptionText1:
        "Ahora tus clientes podrán elegir si quieren entrega a domicilio o retiro en tienda al comprar tus productos en tu tienda virtual",
      descriptionText2:
        "Esta nueva modalidad se puede activar o desactivar fácilmente desde el backoffice de la plataforma. Todos los pedidos de los clientes ahora llevan la especificación de si deben ser entregados en su casa o el cliente los recogerá en tu tienda",
    },
    "v0.13.0": {
      title: "Cupones de descuento",
      descriptionText1:
        "A través de esta nueva funcionalidad podrás regalar a tus seguidores cupones que al comprar en tu tienda virtual podrán activar para obtener descuentos personalizados en sus compras",
      descriptionText2:
        "Puedes generar tantos cupones como quieras, añadirles descuentos específicos (importes de descuento fijos o porcentajes sobre el total de la compra), limitar el número de usos y establecer una fecha de caducidad. Todo de forma fácil e intuitiva desde tu backoffice",
      improvementsText1:
        "Solucionamos un problema relacionado con la no lectura de zonas y ubicaciones de entrega cuando los clientes no especificaban la ubicación de entrega al comprar",
    },
    "v0.12.0": {
      title: "Blogs y galerías en tu Tienda Virtual",
      descriptionText1:
        "Con esta nueva funcionalidad es posible crear páginas personalizadas de blog o galería de imágenes, a las que tus clientes pueden ingresar desde tu tienda virtual",
      descriptionText2:
        "Llamamos Blogs a una página donde puedes escribir cualquier tipo de artículo, pudiendo agregar imágenes, títulos, subtítulos, citas, entre otros. Por otro lado, a través de Galerías podrás publicar fotos (eventos, noticias, productos destacados, entre otros) en un formato hermoso y atractivo para que tus seguidores las vean en tu tienda virtual",
      improvementsText1:
        "Resolvimos un problema detectado al mostrar el stock total de un producto en la lista de productos, cuando consta de múltiples variantes, cada una con un stock diferente",
    },
    "v0.11.0": {
      title: "Volumen de productos",
      descriptionText1:
        "Agregamos la opción de especificar el alto, largo, ancho y peso de cada uno de tus productos, para que tengas una definición más detallada del producto y una forma mucho más precisa de calcular tus costos de envío",
      li1: "Hicimos una serie de mejoras en el rendimiento de la carga de pedidos de tus clientes, para que ahora se carguen y puedas gestionarlos mucho más rápido",
      li2: "Se solucionó el problema detectado al actualizar los enlaces de navegación y que los cambios no se reflejaban automáticamente en el backoffice",
    },
    "v0.10.0": {
      title: "Posicionamiento de enlaces de navegación",
      descriptionText1:
        "Agregamos la posibilidad de posicionar los enlaces de navegación de tu tienda virtual en el orden que desees",
    },
    "v0.9.0": {
      title: "Enlaces de navegación",
      descriptionText1:
        "En esta nueva versión de Grafito hemos incluido la posibilidad de que tú mismo crees los menús de navegación de tu tienda virtual. De esta forma, podrás personalizar mejor la forma en que tus seguidores visitan tu tienda virtual, permitiéndote clasificar mejor tus categorías y subcategorías, resaltar contenidos específicos de tu marca y mejorar la experiencia en la que tus clientes navegan por tu tienda",
    },
    "v0.8.0": {
      title: "Imágenes en páginas personalizadas",
      descriptionText1:
        "Incluimos la opción de insertar imágenes en las páginas personalizadas de tu tienda virtual. Puedes agregar múltiples imágenes dentro de tu contenido para que sea más nutritivo para tus seguidores",
    },
    "v0.7.0": {
      title: "Orientación de la barra de navegación de tu tienda virtual",
      descriptionText1:
        "En esta versión de Grafito incluimos la posibilidad de que puedas configurar la barra de navegación para que se muestre horizontal o verticalmente, de tal forma de ofrecer una forma minimalista y más explícita de mostrar los enlaces de tu contenido en tu tienda virtual",
      improvementsText1:
        "Realizamos múltiples mejoras en el manejo de descuentos por producto y por variante",
    },
    "v0.6.0": {
      title: "Firma automática de términos y condiciones de uso",
      descriptionText1:
        "Hemos resumido en un solo documento todos nuestros términos y condiciones de uso de la plataforma, los cuales pueden ser aceptados digitalmente en tu backoffice. Esto permite que nuestras marcas se incorporen mucho más rápido y las libera de documentación innecesaria",
      improvementsText1:
        "Hicimos que el proceso de creación o actualización de productos y sus variantes sea mucho más fácil e intuitivo. Eliminamos algunos procesos innecesarios en el lado del usuario e hicimos que el flujo de trabajo fuera mucho más rápido",
    },
    "v0.5.0": {
      title: "Precio y SKU por variante de producto",
      descriptionText1:
        "Ahora cada variante de producto puede tener su propio precio y SKU, independientemente del producto al que pertenezca",
      descriptionText2:
        "Esto te proporcionará mucha más flexibilidad a la hora de crear y clasificar tus productos, así como una mejor experiencia de usuario para tus seguidores a la hora de elegir tus productos",
      li1: "Definimos como criterio de orden automático para listar a tus seguidores, la fecha de su última interacción con tu marca (compra en este caso)",
      li2: "Incluimos los campos opcionales de teléfono y RUT para tus seguidores",
      li3: "Solucionamos algunos problemas visuales al mostrar la lista de categorías de productos desde dispositivos pequeños",
    },
    "v0.4.0": {
      title: "Manejo del estado de pedidos",
      descriptionText1:
        "Hemos incluido una funcionalidad muy importante para nuestras marcas: la posibilidad de que gestiones el ciclo de vida de tus pedidos y que tus clientes estén informados en tiempo real de sus cambios",
      descriptionText2:
        "Esto quiere decir que ahora puedes gestionar el estado de tus pedidos (recibidos, en preparación, en despacho, despachados, cancelados) y nosotros nos encargamos de que tus clientes estén al tanto de cada movimiento",
    },
    "v0.3.0": {
      title: "Rediseño de UI/UX",
      descriptionText1:
        "Hemos realizado importantes mejoras en el diseño de la plataforma, tanto para tu backoffice como para tu tienda virtual. Hemos introducido un diseño de interfaz completamente nuevo, que le brindará a usted y a sus seguidores una experiencia de uso totalmente intuitiva, fresca y agradable",
    },
    "v0.2.0": {
      title: "Notas personalizadas para el checkout",
      descriptionText1:
        "En esta versión introducimos la posibilidad de que puedas añadir notas, sea cual sea el anuncio que quieras dar, para tus clientes en el momento del pago de su compra",
      descriptionText2:
        "Simplemente agrega el texto desde tu backoffice y tus clientes lo verán cuando paguen su compra",
      li1: "Resolvimos un error relacionado con el manejo de estados (activado o desactivado) de categorías de productos. Anteriormente la acción de cambiar el estado no se reflejaba en la tienda virtual, con las mejoras incorporadas la funcionalidad volvió a la normalidad",
      li2: "Hemos mejorado el proceso de creación de productos, para que la plataforma actualice automáticamente la lista de productos después de que se crean",
    },
    "v0.1.0": {
      title: "Lanzamiento de nuestra plataforma",
      descriptionText1:
        "Lanzamos nuestra primera versión al público. En esta primera versión incluimos múltiples funcionalidades fundamentales para nuestros creadores, entre las que destacan: gestión de productos e inventario, gestión de pedidos y pagos, gestión de clientes, estadísticas básicas de ventas y una tienda virtual 100% gestionable para que tus seguidores puedan conocer tu proyecto y comprar tus productos",
      descriptionText2:
        "La plataforma está compuesta por dos aplicaciones, un backoffice y una tienda virtual con dominio propio para cada una de nuestros creadores (con carrito de compras y proceso de pago integrados)",
    },
  },
  footer: {
    aboutLinkText: "Nosotros",
    plansLinkText: "Planes",
    changelogLinkText: "Changelog",
    copyrightText: "© {{copyrightYear}} Grafito.app - Designed Worldwide",
  },
  localesSwitcher: {
    title: "Cambiar idioma",
    description:
      "Selecciona el idioma en el que quieres ver nuestro sitio web.",
  },
};
