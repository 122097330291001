import styled from "styled-components";

export const OurPhilosophyContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 152px 72px 72px 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 112px 40px 40px 40px;
  }
`;

export const OurPhilosophyTitle = styled.h1`
  font-size: 64px;
  font-weight: 900;
  color: ${({ theme }) => theme.colorsPalette.white.default};
  margin-top: 0;
  margin-bottom: 36px;

  @media (max-width: 600px) {
    font-size: 32px;
    margin: 0 0 24px 0;
    line-height: 1em;
  }
`;

export const OurPhilosophyImgContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 188px;
  margin-top: 16px;
`;

export const OurPhilosophyImg = styled.img`
  position: absolute;
  height: 320px;
  right: -100px;
  top: 24px;
  border-radius: 12px;

  @media (max-width: 600px) {
    right: -104px;
    top: 0;
    height: 100%;
    border-radius: 2px;
  }
`;

export const TeamContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.dark};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 40px;
  }
`;

export const TeamInnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 16px;
  max-width: 896px;
  margin: auto;
  margin-top: 48px;

  @media (max-width: 600px) {
    max-width: unset;
  }
`;

export const TeamMemberSocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 44px;
  margin-top: 16px;
`;

export const TeamMemberAvatarImg = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
`;

export const TeamMemberHeader = styled.h1`
  margin: 16px 0 0 0;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

export const TeamMemberSubheader = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: 600;
  margin: 4px 0 8px 0;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    margin-bottom: 8px;
    max-width: 188px;
  }
`;
