import { theme } from "kosmos-ui/lib";

function LayersIcon({ height = "231px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 52 52" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor={theme.colorsPalette.blue.dark} offset="0%" />
          <stop stopColor={theme.colorsPalette.cyan.default} offset="100%" />
        </linearGradient>
      </defs>

      <path
        fill="url(#gradient)"
        d="M51,16.5c0-0.4-0.2-0.7-0.5-0.9l-24-13.2c-0.3-0.2-0.7-0.2-1,0l-24,13.2C1.2,15.8,1,16.1,1,16.5s0.2,0.7,0.5,0.9l7,3.9   l-7,3.9C1.2,25.3,1,25.6,1,26s0.2,0.7,0.5,0.9l7,3.9l-7,3.9C1.2,34.8,1,35.1,1,35.5s0.2,0.7,0.5,0.9l24,13.3   c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1l24-13.3c0.3-0.2,0.5-0.5,0.5-0.9s-0.2-0.7-0.5-0.9l-7-3.9l7-3.9   c0.3-0.2,0.5-0.5,0.5-0.9s-0.2-0.7-0.5-0.9l-7-3.9l7-3.9C50.8,17.2,51,16.8,51,16.5z M47.9,35.5L26,47.6L4.1,35.5l6.6-3.6l14.9,8.2   c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1l14.9-8.2L47.9,35.5z M47.9,26c-2.9,1.6,1.8-1-21.9,12.1C25.1,37.6,8.9,28.6,4.1,26   l6.6-3.6l14.9,8.2c0.3,0.2,0.7,0.2,1,0l14.9-8.2L47.9,26z M26,28.6c-5.5-3-18.4-10.2-21.9-12.1L26,4.4l21.9,12.1   C44.7,18.3,49.7,15.5,26,28.6z"
      />
    </svg>
  );
}

export default LayersIcon;
