import { ReactNode } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme } from "kosmos-ui";

const GlobalStyle = createGlobalStyle`
  p {
    line-height: 24px;
    margin: 0;
    font-size: ${theme.fontSizes.regular};
    color: ${theme.colorsPalette.gray.dark};
  }
`;

// TODO: Remove this workaround when styled-components support React 18.
const GlobalStyleProxy: any = GlobalStyle;
const ThemeProviderProxy: any = ThemeProvider;

export default function ThemeContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <ThemeProviderProxy theme={theme}>
      <GlobalStyleProxy />

      {children}
    </ThemeProviderProxy>
  );
}
