import { useTranslation } from "react-i18next";
import { Card, theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import Typography from "app/components/Typography/Typography";
import PosterCard from "app/components/PosterCard/PosterCard";
import { TEAM } from "app/constants/app.constants";
import GalleryHomeImg from "../../assets/images/gallery-home.png";
import { GithubIcon, LinkedInIcon } from "app/assets/icons";

import {
  OurPhilosophyContainer,
  OurPhilosophyTitle,
  OurPhilosophyImgContainer,
  OurPhilosophyImg,
  TeamContainer,
  TeamInnerContainer,
  TeamMemberSocialMediaContainer,
  TeamMemberAvatarImg,
  TeamMemberHeader,
  TeamMemberSubheader,
} from "./AboutUs.styles";

export function OurPhilosophy() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  return (
    <OurPhilosophyContainer>
      <PosterCard
        gradientFrom={theme.colorsPalette.blue.default}
        gradientTo={theme.colorsPalette.cyan.default}
        style={{ maxWidth: "calc(1200px - 112px)" }}
      >
        <OurPhilosophyTitle
          style={{ opacity: 0.4, marginBottom: mobile ? 8 : -16 }}
        >
          {t("about.ourPhilosophy.title1")}.
        </OurPhilosophyTitle>

        <OurPhilosophyTitle>
          {t("about.ourPhilosophy.title2")}.
        </OurPhilosophyTitle>

        <Typography
          as="p"
          variant="paragraph1"
          style={{
            color: theme.colorsPalette.white.default,
            textAlign: "left",
            fontWeight: theme.fontWeights.bold,
            margin: 0,
            maxWidth: 560,
          }}
        >
          {t("about.ourPhilosophy.descriptionText")}.
        </Typography>

        {mobile ? (
          <OurPhilosophyImgContainer>
            <OurPhilosophyImg src={GalleryHomeImg} />
          </OurPhilosophyImgContainer>
        ) : (
          <OurPhilosophyImg src={GalleryHomeImg} />
        )}
      </PosterCard>
    </OurPhilosophyContainer>
  );
}

export function Team() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  return (
    <TeamContainer>
      <Typography as="h1" variant="title2">
        {t("about.team.title")}.
      </Typography>

      <Typography
        as="h2"
        variant="paragraph1"
        style={{ textAlign: "center", maxWidth: 760 }}
      >
        {t("about.team.subtitle")}
      </Typography>

      <TeamInnerContainer>
        {TEAM.map((member, index) => (
          <Card
            key={index}
            style={{
              padding: mobile ? "32px 24px" : "32px 52px",
              alignItems: "center",
              backgroundColor: "#1C1D1F",
            }}
          >
            <TeamMemberAvatarImg src={member.avatar} />

            <TeamMemberHeader>{member.name}</TeamMemberHeader>

            <TeamMemberSubheader>{member.role}</TeamMemberSubheader>

            {member.experience}

            {member.socialMedia && (
              <TeamMemberSocialMediaContainer>
                {member.socialMedia.linkedIn && (
                  <a
                    href={member.socialMedia.linkedIn}
                    style={{ marginRight: member.socialMedia.github ? 8 : 0 }}
                  >
                    <LinkedInIcon variant="gradient" height="16px" />
                  </a>
                )}

                {member.socialMedia.github && (
                  <a href={member.socialMedia.github}>
                    <GithubIcon variant="gradient" height="16px" />
                  </a>
                )}
              </TeamMemberSocialMediaContainer>
            )}
          </Card>
        ))}
      </TeamInnerContainer>
    </TeamContainer>
  );
}
