import { theme } from "kosmos-ui/lib";

export default function OctahedronIcon({ height = "80px", style }: TIconProps) {
  return (
    <svg height={height} style={style} viewBox="0 0 100 100">
      <defs>
        <linearGradient id="gradient">
          <stop stopColor={theme.colorsPalette.blue.dark} offset="0%" />
          <stop stopColor={theme.colorsPalette.cyan.default} offset="100%" />
        </linearGradient>
      </defs>

      <path
        fill="url(#gradient)"
        d="M85.758,50.127c-0.021-0.09-0.04-0.176-0.079-0.26c-0.042-0.092-0.102-0.168-0.164-0.246   c-0.035-0.045-0.052-0.1-0.094-0.141L50.506,14.559c-0.016-0.016-0.038-0.021-0.055-0.035c-0.077-0.07-0.171-0.119-0.264-0.172   c-0.057-0.029-0.106-0.064-0.167-0.086c-0.033-0.014-0.057-0.039-0.093-0.049c-0.107-0.031-0.215-0.023-0.322-0.027   c-0.024-0.002-0.05-0.008-0.074-0.008c-0.146,0.002-0.283,0.029-0.416,0.076c-0.039,0.012-0.075,0.021-0.112,0.037   c-0.104,0.047-0.194,0.111-0.284,0.184c-0.039,0.029-0.088,0.045-0.124,0.08L14.566,48.592c-0.025,0.025-0.033,0.061-0.058,0.088   c-0.057,0.066-0.101,0.139-0.144,0.217c-0.047,0.082-0.089,0.16-0.115,0.248c-0.01,0.033-0.034,0.057-0.042,0.09   c-0.012,0.051,0.001,0.1-0.005,0.152c-0.013,0.092-0.018,0.182-0.01,0.275c0.007,0.086,0.025,0.164,0.048,0.246   c0.023,0.084,0.052,0.162,0.09,0.24c0.042,0.084,0.093,0.154,0.151,0.227c0.031,0.041,0.048,0.09,0.084,0.129L49.48,85.422   c0.021,0.021,0.051,0.029,0.071,0.049c0.091,0.08,0.188,0.146,0.297,0.199c0.042,0.02,0.081,0.041,0.124,0.057   c0.148,0.055,0.303,0.092,0.464,0.092c0.181,0,0.357-0.037,0.52-0.105c0.001,0,0.003,0,0.003,0   c0.037-0.016,0.061-0.047,0.097-0.066c0.12-0.061,0.238-0.127,0.336-0.225l0.01-0.008c0.001-0.002,0.001-0.002,0.003-0.004   l34.017-34.02c0.017-0.016,0.021-0.037,0.034-0.053c0.066-0.07,0.111-0.154,0.16-0.238c0.041-0.07,0.087-0.137,0.113-0.211   c0.027-0.076,0.033-0.156,0.047-0.236c0.016-0.098,0.033-0.188,0.028-0.285c-0.001-0.021,0.01-0.041,0.007-0.062   C85.804,50.24,85.772,50.188,85.758,50.127z M46.777,20.199l-9.653,33.129l-18.938-4.533L46.777,20.199z M19.861,51.977   l17.219,4.119l9.454,22.557L19.861,51.977z M50.903,82.088L40.018,56.109l40.827-3.965L50.903,82.088z M39.919,53.402l10.29-35.318   l31.277,31.283L39.919,53.402z"
      />
    </svg>
  );
}
