import styled from "styled-components";

import mariellaAvatar from "app/assets/images/mariellaAvatar.jpg";
import clementeAvatar from "app/assets/images/clementeAvatar.jpg";
import alonsoAvatar from "app/assets/images/alonsoAvatar.png";
import antonioAvatar from "app/assets/images/antonioAvatar.png";
import alvaroAvatar from "app/assets/images/alvaroAvatar.png";
import marciaAvatar from "app/assets/images/marciaAvatar.png";
import sebaAvatar from "app/assets/images/sebaAvatar.jpg";

export const TeamMemberParagraph = styled.p`
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: 400;
  line-height: 1.4;
  margin-top: 4px;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    max-width: 188px;
  }
`;

const TeamMemberSpan = styled.span`
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: 600;
  line-height: 1.4;
`;

export const TEAM: TTeamMember[] = [
  {
    avatar: mariellaAvatar,
    name: "Mariella Rivera",
    role: "CSO & Co-Founder",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> Director of Creativity at J.
        Walter Thompson Company, Chile.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.linkedin.com/in/cs-45112/",
    },
  },
  {
    avatar: clementeAvatar,
    name: "Clemente Sutil",
    role: "CEO & Co-Founder",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> Senior Software Engineer at Lisk
        Foundation, Berlin.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.linkedin.com/in/cs-45112/",
      github: "https://github.com/ClementeSerrano",
    },
  },
  {
    avatar: marciaAvatar,
    name: "Marcia Copier",
    role: "Innovation & Strategy",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> CEO at Santiago Innova, Chile.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.linkedin.com/in/marciacopier/",
    },
  },
  {
    avatar: alonsoAvatar,
    name: "Alonso Salas",
    role: "CTO",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> Tech Lead & Principal Software
        Engineer at Globant, USA.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.linkedin.com/in/alosalasv/",
      github: "https://github.com/AlonsoSalas",
    },
  },
  {
    avatar: antonioAvatar,
    name: "Antonio Mejías",
    role: "Engineering",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> Platform & Architecture Engineer
        en Cornershop, Chile.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.linkedin.com/in/antonio-mejias-399464120/",
      github: "https://github.com/antonio94js",
    },
  },
  {
    avatar: alvaroAvatar,
    name: "Álvaro Silva",
    role: "CFO",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> CFO at Pluss Marketing, Chile
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn:
        "https://www.linkedin.com/in/alvaro-esteban-silva-lira-06536326/",
    },
  },
  {
    avatar: sebaAvatar,
    name: "Sebastián Vera",
    role: "Product Design",
    experience: (
      <TeamMemberParagraph>
        <TeamMemberSpan>Past:</TeamMemberSpan> Director of Photography & Design
        at Vera, Chile.
      </TeamMemberParagraph>
    ),
    socialMedia: {
      linkedIn: "https://www.instagram.com/sebastvera/",
    },
  },
];
