import { useQuery } from "@apollo/client";

import { GET_COMPANIES_PUBLIC_PROFILE } from "./companies.gql.docs";
import { TGetCompaniesPublicProfileData } from "./companies.gql.types";

export function useGetCompaniesPublicProfileQuery() {
  const query = useQuery<TGetCompaniesPublicProfileData>(
    GET_COMPANIES_PUBLIC_PROFILE
  );

  return query;
}
