export default function CloudSyncIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 510.000000 358.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#fffb00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,358.000000) scale(0.100000,-0.100000)"
        fill="url(#gradient)"
        stroke="none"
      >
        <path
          d="M2480 3544 c-519 -64 -973 -341 -1256 -765 -69 -103 -148 -256 -184
-357 -19 -51 -27 -63 -49 -68 -421 -84 -746 -368 -890 -778 -80 -228 -82 -513
-5 -745 60 -182 179 -369 316 -496 159 -148 331 -239 543 -288 89 -21 108 -21
1620 -25 1631 -4 1679 -2 1828 44 372 117 626 454 667 884 16 167 -7 343 -65
496 -102 270 -300 478 -545 570 l-84 32 -12 69 c-104 615 -563 1146 -1164
1346 -175 59 -300 79 -505 82 -99 2 -196 1 -215 -1z m480 -249 c194 -43 410
-137 565 -247 115 -81 282 -248 364 -363 72 -103 169 -294 205 -408 28 -85 66
-270 66 -318 0 -39 24 -85 52 -100 12 -7 55 -20 96 -30 243 -61 424 -248 509
-526 24 -79 27 -104 27 -248 1 -138 -2 -171 -22 -240 -81 -282 -272 -480 -528
-545 -75 -20 -115 -20 -1641 -20 -1717 0 -1628 -3 -1791 60 -124 48 -209 106
-317 214 -180 182 -267 376 -281 626 -15 265 83 519 275 710 145 145 299 225
501 259 139 24 148 32 189 157 175 542 634 933 1211 1033 86 15 432 6 520 -14z"
        />
        <path
          d="M2335 2516 c-176 -46 -320 -131 -451 -268 -160 -167 -250 -361 -273
-587 -28 -268 55 -514 256 -761 l72 -88 -120 -4 -119 -3 -32 -33 c-27 -27 -33
-40 -33 -76 0 -30 6 -51 20 -66 40 -45 71 -50 295 -50 324 0 311 -13 348 358
14 142 22 270 18 286 -4 16 -20 41 -36 57 -42 43 -100 41 -145 -4 -30 -30 -33
-40 -40 -112 -10 -126 -12 -127 -75 -58 -70 76 -123 167 -157 268 -25 72 -27
94 -27 220 0 124 3 149 27 220 37 112 90 197 177 286 126 127 286 204 453 216
45 3 96 12 114 20 31 14 63 64 63 98 0 25 -27 72 -52 89 -35 24 -173 20 -283
-8z"
        />
        <path
          d="M2883 2475 c-41 -18 -45 -35 -73 -303 -24 -231 -22 -255 26 -287 13
-8 42 -15 65 -15 60 0 104 46 113 118 3 28 9 75 12 103 l6 51 35 -34 c75 -72
144 -184 183 -298 34 -100 39 -279 12 -388 -79 -311 -326 -522 -647 -552 -114
-11 -143 -26 -162 -84 -15 -43 -1 -87 37 -121 19 -17 35 -20 119 -18 242 4
513 131 671 313 317 366 300 901 -40 1237 l-61 61 116 4 117 3 34 37 c54 60
39 144 -31 174 -49 20 -485 20 -532 -1z"
        />
      </g>
    </svg>
  );
}
