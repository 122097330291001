import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import Routes from "./routes/Routes";
import gqlClient from "../api/graphql/client.gql";
import { CompaniesContextProvider } from "./context/CompaniesContext/CompaniesContext";
import ThemeContextProvider from "./context/ThemeContext/ThemeContext";
import { LayoutContextProvider } from "./context/LayoutContext/LayoutContext";

import "../locales/i18n";

export default function App() {
  return (
    <Router>
      <ApolloProvider client={gqlClient}>
        <CompaniesContextProvider>
          <ThemeContextProvider>
            <LayoutContextProvider>
              <Routes />
            </LayoutContextProvider>
          </ThemeContextProvider>
        </CompaniesContextProvider>
      </ApolloProvider>
    </Router>
  );
}
