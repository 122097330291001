import styled from "styled-components";

export const CompaniesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 40px;
  }
`;

export const CompaniesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  width: 100%;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }
`;

export const CompaniesTitle = styled.h1`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorsPalette.blue.default};
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const CompaniesAnchor = styled.a`
  justify-self: center;
`;

export const CompanyLogoImg = styled.img`
  height: 124px;

  @media (max-width: 600px) {
    height: 88px;
  }
`;
