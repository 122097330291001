import styled from "styled-components";

export const PlansContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 152px 72px 88px 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};
  min-height: calc(100vh - 360px);

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 112px 40px 40px 40px;
  }
`;

export const PlansCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 16px;
  width: 100%;
  max-width: 1200px;
  margin: 32px auto;

  @media (max-width: 600px) {
    max-width: unset;
    margin: unset;
  }
`;

export const PlanFeatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  color: #8f9ab7;
  width: 100%;
`;

export const PlanIconContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
