import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, LocaleFlagIcon, theme } from "kosmos-ui/lib";

import Button from "../Button/Button";
import Typography from "../Typography/Typography";
import { SUPPORTED_LANGUAGES } from "app/constants/locales.constants";

import { TLocalesSwitcherProps } from "./LocalesSwitcher.types";
import {
  localesSwitcherDialogStyles,
  localesSwitcherLanguageButtonStyles,
} from "./LocalesSwitcher.styles";

export default function LocalesSwitcher(props: TLocalesSwitcherProps) {
  const [showLocaleSelectorDialog, setShowLocaleSelectorDialog] =
    useState(false);

  const { i18n, t } = useTranslation();

  const activeLanguageCode = i18n.language as TSupportedLanguageCode;

  function handleLocaleChangeClick(languageCode: TSupportedLanguageCode) {
    i18n.changeLanguage(languageCode);

    setShowLocaleSelectorDialog(false);
  }

  return (
    <>
      <Button
        onClick={() => setShowLocaleSelectorDialog(true)}
        variant="secondary"
        format="link"
        style={props.style?.showDialogButton}
        className={props.className?.showDialogButton}
      >
        <LocaleFlagIcon
          languageCode={activeLanguageCode}
          height="32px"
          style={props.style?.flag}
          className={props.className?.flag}
        />
      </Button>

      <Dialog
        show={showLocaleSelectorDialog}
        style={localesSwitcherDialogStyles}
      >
        <Dialog.Header>
          <Dialog.Title style={{ color: theme.colorsPalette.white.default }}>
            {t("localesSwitcher.title")}
          </Dialog.Title>

          <Dialog.Close
            onClick={() => setShowLocaleSelectorDialog(false)}
            style={{ backgroundColor: "transparent" }}
          />
        </Dialog.Header>

        <Dialog.Body>
          <Typography as="p" variant="paragraph3" style={{ marginBottom: 16 }}>
            {t("localesSwitcher.description")}
          </Typography>

          {SUPPORTED_LANGUAGES.map((language) => (
            <Button
              key={language.code}
              onClick={() => handleLocaleChangeClick(language.code)}
              variant="secondary"
              format="outline"
              size="small"
              style={{
                ...localesSwitcherLanguageButtonStyles,
                border: `2px solid ${
                  activeLanguageCode === language.code
                    ? // TODO: Update kosmos-ui theme.colorsPalette.gray.light to fit this color.
                      "#6b6d76"
                    : theme.colorsPalette.black.light
                }`,
              }}
            >
              <LocaleFlagIcon
                languageCode={language.code}
                height="32px"
                style={{ marginRight: 8 }}
              />

              <Typography as="p" variant="paragraph3">
                {language.nativeName}
              </Typography>
            </Button>
          ))}
        </Dialog.Body>
      </Dialog>
    </>
  );
}
