import { useTranslation } from "react-i18next";
import { Card, theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import Typography from "app/components/Typography/Typography";

import {
  CompetitiveAdvantagesContainer,
  CompetitiveAdvantagesCardsContainer,
  CompetitiveAdvantagesIconContainer,
} from "./CompetitiveAdvantages.styles";
import ValueIcon from "app/assets/icons/ValueIcon";
import TeamworkIcon from "app/assets/icons/TeamworkIcon";
import BeautifulIcon from "app/assets/icons/BeautifulIcon";
import CheapIcon from "app/assets/icons/CheapIcon";
import RocketIcon from "app/assets/icons/RocketIcon";
import CloudSyncIcon from "app/assets/icons/CloudSyncIcon";

export default function CompetitiveAdvantages() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  return (
    <CompetitiveAdvantagesContainer>
      <Typography as="h1" variant="title2" style={{ textAlign: "center" }}>
        {t("home.competitiveAdvantages.title1")} <br />
        {t("home.competitiveAdvantages.title2")} <br />
        {t("home.competitiveAdvantages.title3")}.
      </Typography>

      <CompetitiveAdvantagesCardsContainer>
        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <ValueIcon height="40px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.localAndExclusive.title")}.
          </Typography>

          <Typography as="p" variant="paragraph2">
            {t("home.competitiveAdvantages.localAndExclusive.descriptionText")}.
          </Typography>
        </Card>

        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <TeamworkIcon height="44px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.communityOriented.title")}.
          </Typography>

          {/* Add: we help each other and work together (more teamwork oriented). */}
          <Typography as="p" variant="paragraph2">
            {t("home.competitiveAdvantages.communityOriented.descriptionText")}.
          </Typography>
        </Card>

        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <BeautifulIcon height="32px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.beautifulAndEasyToUse.title")}.
          </Typography>

          <Typography as="p" variant="paragraph2">
            {t(
              "home.competitiveAdvantages.beautifulAndEasyToUse.descriptionText"
            )}
            .
          </Typography>
        </Card>

        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <CheapIcon height="40px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.fairPrice.title")}.
          </Typography>

          <Typography as="p" variant="paragraph2">
            {t("home.competitiveAdvantages.fairPrice.descriptionText")}.
          </Typography>
        </Card>

        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <RocketIcon height="40px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.personalizedGuidance.title")}.
          </Typography>

          <Typography as="p" variant="paragraph2">
            {t(
              "home.competitiveAdvantages.personalizedGuidance.descriptionText"
            )}
            .
          </Typography>
        </Card>

        <Card
          style={{
            padding: mobile ? "32px 24px" : "32px 52px",
            alignItems: mobile ? "center" : "unset",
            backgroundColor: "#1C1D1F",
          }}
        >
          <CompetitiveAdvantagesIconContainer>
            <CloudSyncIcon height="32px" />
          </CompetitiveAdvantagesIconContainer>

          <Typography as="h2" variant="title3">
            {t("home.competitiveAdvantages.multiSalesChannels.title")}.
          </Typography>

          <Typography as="p" variant="paragraph2">
            {t(
              "home.competitiveAdvantages.multiSalesChannels.descriptionText1"
            )}{" "}
            <Typography
              as="a"
              href="https://galeria.grafito.app/"
              target="_blank"
              variant="paragraph2"
              style={{ color: theme.colorsPalette.white.default }}
            >
              {t(
                "home.competitiveAdvantages.multiSalesChannels.descriptionText2"
              )}
            </Typography>{" "}
            {t(
              "home.competitiveAdvantages.multiSalesChannels.descriptionText3"
            )}
            .
          </Typography>
        </Card>
      </CompetitiveAdvantagesCardsContainer>
    </CompetitiveAdvantagesContainer>
  );
}
