import { CSSProperties } from "react";
import { TTypographyVariant } from "kosmos-ui/lib/components/Typography/types";
import { theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";

export function useTypographyStyles({
  variant,
}: {
  variant: TTypographyVariant;
}): CSSProperties | undefined {
  const { mobile } = useLayout();

  switch (variant) {
    case "title1":
      return {
        color: theme.colorsPalette.white.default,
        fontWeight: theme.fontWeights.bolder,
        textAlign: mobile ? "center" : "left",
      };

    case "title2":
      return {
        color: theme.colorsPalette.white.default,
        fontWeight: theme.fontWeights.bolder,
        fontSize: mobile ? theme.fontSizes.xxlarge : 48,
        textAlign: "center",
        marginBottom: mobile ? 32 : 48,
      };

    case "title3":
      return {
        color: theme.colorsPalette.white.default,
        fontWeight: theme.fontWeights.bolder,
        fontSize: mobile
          ? theme.fontSizes.regularLarge
          : theme.fontSizes.xlarge,
        textAlign: mobile ? "center" : "left",
      };

    case "paragraph1":
      return {
        fontSize: mobile ? 18 : theme.fontSizes.xlarge,
        maxWidth: 560,
        marginBottom: 24,
        textAlign: mobile ? "center" : "left",
      };

    case "paragraph2":
      return {
        fontSize: mobile ? 18 : 16,
        fontWeight: theme.fontWeights.regular,
        textAlign: mobile ? "center" : "left",
      };

    case "paragraph3":
      return {
        fontWeight: theme.fontWeights.regular,
      };

    default:
      break;
  }
}
