export const EN_TRANSLATIONS: TLocaleTranslation = {
  intlDateTime: "On {{val, datetime}}",
  navbar: {
    aboutLinkText: "About us",
    plansLinkText: "Plans",
    changelogLinkText: "Changelog",
    signInButtonText: "Log in",
  },
  home: {
    intro: {
      title1: "The future",
      title2: "is local",
      subtitle:
        "A platform that connects the thriving community of local entrepreneurs all across the globe",
      goToPlatformBtnText: "Go to platform",
    },
    companies: {
      title: "Empowering independent creators",
      andMoreText: "and more",
    },
    features: {
      businessManagement: {
        title: "All your business pipelines in a single place",
        descriptionText:
          "Powerful and intuitive tools that help you manage all your business easily. Products inventory, order execution, customers management, shipping & delivery, advanced analytics and more",
      },
      socialNetwork: {
        title: "Get discovered by new people",
        descriptionText:
          "Publish products and exclusive content in our platform and reach thousands of new people interested in unique and independent projects",
      },
      collaborative: {
        title: "Collaborate with other creators",
        descriptionText:
          "Access to our local commerce network and empower your sales with more creators as you. Cross-sell & collaborative campaigns, knowledge exchange programs, multi-brand stores, and more",
      },
      ownVirtualSpace: {
        title: "Own-domain Virtual Store",
        descriptionText:
          "Your followers will be able to register and enjoy exclusive content from your brand, receive smart recommendations and buy through a fresh and intuitive Virtual Store",
      },
      analytics: {
        title: "Real insights of your audience",
        descriptionText:
          "Learn about your followers, about their profiles and preferences. Reach them with right what they want",
      },
    },
    competitiveAdvantages: {
      title1: "A place where",
      title2: "independent brands meet",
      title3: "independent people",
      localAndExclusive: {
        title: "Local & Exclusive brands",
        descriptionText:
          "Grafito is made up only by local creators. With us you will find projects with unique proposals and with highly creative & exclusive products",
      },
      communityOriented: {
        title: "Community oriented",
        descriptionText:
          "We are a community who believe in the local value, supports it and contribute to make it growth and competitive",
      },
      beautifulAndEasyToUse: {
        title: "Beautiful and easy to use",
        descriptionText:
          "Intuitive, robust and meticulous designed features to unlock your project's potential and give your followers a fresh shopping experience",
      },
      fairPrice: {
        title: "Fair price",
        descriptionText:
          "Flexible and scalable prices designed for the different stages of your project. Elastic pricing model, where if you win, we win",
      },
      personalizedGuidance: {
        title: "Personalized Guidance & Accompaniment",
        descriptionText:
          "We are compromised with our creators, and walk step by step with them in growing their projects. We put our experience in disposition to guide you through all the phases of your project",
      },
      multiSalesChannels: {
        title: "Multi-sales channels, simultaneously",
        descriptionText1:
          "Being part of our community will let you be discovered in our",
        descriptionText2: "Gallery",
        descriptionText3:
          "and have your own-domain Virtual Store. Simultaneously and with no extra configurations",
      },
    },
    testimonials: {
      title: "Used by entrepreneurs",
      t1: {
        actorNameText: "Bárbara Cantó",
        actorRoleText: "Founder of Woolie Beanies",
        testimonialText:
          "Grafito is a super good solution for entrepreneurs looking to quickly and efficiently mount their businesses online and connect with other creators. The team behind it's super cool and attentive to solve all kinds of problems. Also very open to constantly improving their service. 100% recommended",
      },
      t2: {
        actorNameText: "Isidora de la Sotta",
        actorRoleText: "Founder of Etnik and Minga Kids",
        testimonialText:
          "With Grafito I was able to organize my business much more. It has made my job so much easier and clearer. Now I can manage my sales, products and clients much more efficiently and spend more time making decisions",
      },
      t3: {
        actorNameText: "Andrea Errazuriz",
        actorRoleText: "Founder of Lambra",
        testimonialText:
          "Incredible solution to show my project in a beautiful, easy and fresh way to my customers. It helps me a lot to maintain an order in my sales. I'm null with technology and it hasn't cost me any effort to use it! Very nice and professional people",
      },
      t4: {
        actorNameText: "Verónica Sutil",
        actorRoleText: "Founder of Ropa Verónica Sutil",
        testimonialText:
          "With Grafito I was able to take my brand to the web. My followers can see my products and buy much more comfortably It definitely improved my sales and number of clients! It's very easy to use and the onboarding was great",
      },
      t5: {
        actorNameText: "Reimundo Musri",
        actorRoleText: "Founder of Reimundo A. Musri",
        testimonialText:
          "Grafito has helped me understand my business better It has powerful tools that have made my work much easier and have allowed me to reach my clients more effectively. I have discovered a lot of artists as me in the platform, as well as new followers interested in my project",
      },
      t6: {
        actorNameText: "Francisca Kegevic",
        actorRoleText: "Co-founder of Hybrida",
        testimonialText:
          "We were looking for options to have ecommerce for a long time and Grafito was the best anyway. Its low rates are perfect for entrepreneurs and the team behind it is tremendous, with an incredible disposition and concern 100% recommended in every way!",
      },
    },
    startToday: {
      title: "Start today with Grafito.app",
      subtitle:
        "We are the most convenient platform for entrepreneurs. State-of-the-art technology to grow your project",
      startButtonText: "Start now",
    },
  },
  about: {
    ourPhilosophy: {
      title1: "By creators",
      title2: "For creators",
      descriptionText:
        "Now more than ever, people supports and prefer local businesses over the big companies of always. There are millions out there, who collectively represent a trillions of dollars market. Our goal is to empower this local businesses and bring them to the next level",
    },
    team: {
      title: "The team",
      subtitle:
        "We are a multidisciplinary team. Engineers, marketers, art directors, audiovisual communicators, auditors. Very different talents, with a common vision: a platform to help local creators to chaise their dreams",
    },
  },
  changelog: {
    title: "Changelog",
    subtitle: "Updates and improvements to our platform",
    improvementsTitle: "Improvements",
    "v1.5.0": {
      title: "One click payments",
      descriptionText1:
        "Now your followers are able to pay in your Virtual Store with only one click! No more tedious forms fulfillment for buying your products. Just shop, add to the basket and pay with one click",
      descriptionText2:
        "We have integrated to Grafito a sophisticated and secure wallet system for your shoppers. Now they can register their payment methods with us and when they pay, just choose which one to use",
    },
    "v1.4.0": {
      title: "Virtual Store internationalization",
      descriptionText1:
        "We have enabled the Multi-language Virtual Store functionality!",
      descriptionText2:
        "Now you can make your Virtual Store to appear in: English (EN), Spanish (ES) or German (DE). This way you can expand your sales and audience to the rest of the world!",
      descriptionText3:
        "Every time your followers enter your virtual stores, we will detect their language (based on their localization and language settings on their device) and translate your virtual store into that language. We also give them the option to easily switch languages (a flag is shown in the navigation bar)",
    },
    "v1.3.0": {
      title: "Shoppers search, sort and pagination",
      descriptionText1:
        "From your Backoffice now is much more easy to visualize your followers. We have enabled the search, sort and pagination functionality, so you can see how your following are interacting with your products and content in a much more clear way",
      improvementsText1:
        "We also updated our Terms and Conditions in order to update our base plan to consider a monthly fixed subscription of $30 + 3% of commission per transaction for our creators",
    },
    "v1.2.0": {
      title: "Products sort and filter",
      descriptionText1:
        "With this version of Grafito, your followers will have access to powerful filters to search for your products faster and easier",
      descriptionText2:
        "They will be able to sort them by name, creation date, price, and more, and also to filter them based on a dynamic price scale and if they have or not discount applied",
    },
    "v1.1.0": {
      title: "Google Analytics integration",
      descriptionText1:
        "We have integrated your Virtual Store with Google Analytics so that you can monitor the behavior of your followers and get better insights about them and the usage of your store",
      descriptionText2:
        "You can find it on the -Apps- section in your Backoffice, where you just have to enter your Google Analytics -Tracking ID- and your store will be connected to the tool",
      descriptionText3:
        "We made a complete tutorial on how to integrate the tool with Grafito, so you are able to start from scratch to build usability and interaction metrics of your customers",
    },
    "v1.0.0": {
      title: "Grafito Gallery",
      descriptionText1:
        "The first version of Grafito has been inaugurated with the launch of our Gallery: Where all ours creators are in a single place. The community",
      descriptionText2: "In the launch of our Gallery you will find:",
      li1: "Smart recommendations of creators profiles, categories and products",
      li2: "New creators, trends, promotions and news",
      li3: "Search, filter and sort by any product, creator, or category",
      li4: "Buy products in creators virtual stores",
    },
    "v0.23.0": {
      title: "Shopping Announcement bar",
      descriptionText1:
        "Now you can publish your ads, promotions, new products, and all kind of announcements in a totally customizable top bar that can be displayed on your Virtual Store for your customers",
      li1: "Improve shopper authentication process to make a more intuitive and fast experience",
      li2: "Improve Virtual Store accessibility for product categories and details. Fix some layout definitions to make it more accessible across multiple devices",
    },
    "v0.22.0": {
      title: "Shoppers universal account",
      descriptionText1:
        "Starting today, your customers will have their own profile in Grafito, where they can save their personal information (names, email, shipping addresses, among others), review their purchases, and configure their profile",
      descriptionText2:
        "They will be able to log in to their virtual stores and have a much more fluid shopping experience",
      descriptionText3:
        "An important feature has been added here, and is that each shopper will have an universal account in Grafito. They can enter to all our creators virtual stores and navigate through our Gallery without need to sign in multiple times, and always maintaining the same profile!",
    },
    "v0.21.0": {
      title: "Orders report",
      descriptionText1:
        "Now from your Backoffice you will able to download at any time a complete orders report of your sales. Select filters, metrics and analytics to download it as Excel file",
    },
    "v0.20.0": {
      title: "Rich text editor for product descriptions",
      descriptionText1:
        "Insert titles, subtitles, images, lists, links, emojis and much more on your products descriptions",
      descriptionText2:
        "We have added a powerful rich content editor tool for all your products descriptions. Compatible with mobile, desktop, and all devices",
      li1: "Fixes on how discount coupons applies on total order amounts",
      li2: "Fixes on Virtual Store scrolling on section changes. Improve user interface and experience when scrolling products",
    },
    "v0.19.0": {
      title: "Product merchants",
      descriptionText1:
        "Now in Grafito you will be able to add products from other merchants in your Virtual Store. Products can have categories and merchants associated",
    },
    "v0.18.0": {
      title: "Search and paginate products",
      descriptionText1:
        "In this release we included 2 fundamental new features to our apps",
      li1: "Search for any product attribute both in your backoffice and virtual store. Use name, category, description and variants and you will see all matches in an easy to navigate list",
      li2: "Big lists of products are now shown in chunks (or parts), so they load much more faster and organized",
    },
    "v0.17.0": {
      title: "Product images sorting",
      descriptionText1:
        "Through this new functionality, you will be able to order all the images of your products as you want",
      descriptionText2:
        "We developed an extremely simple and cool interface so that you can configure the order in which your customers see your products to your liking",
    },
    "v0.16.0": {
      title: "Bulk upload of products",
      descriptionText1:
        "In this new version of the platform we added the functionality to massively upload different types of products at once",
      descriptionText2:
        "This is now possible by uploading a CSV file from the platform's backoffice, within which you can list all the products you want to add in rows, detailing their name, category, price, stock, description, among others. You can also download the sample spreadsheet from the backoffice",
    },
    "v0.15.0": {
      title: "Custom Slideshows",
      descriptionText1:
        "We have added the possibility that you can add personalized Slideshows to the homepage of your virtual store. This means, a section of various images with titles, subtitles and links which change every certain amount of time",
      descriptionText2:
        "Special to publish your promotions, new products or any type of news for your followers",
      descriptionText3:
        "It is possible to configure the height of the slideshow, the opacity of the black veil that is used to highlight the text and the speed with which each slide moves on to the next",
      improvementsText1:
        "We have improved the way in which the summary of your sales, customer evolution, orders and sales by location is displayed on the platform's dashboard",
      improvementsText2:
        "Now you can view the information more easily and apply time filters on the statistics",
    },
    "v0.14.0": {
      title: "Store pickup option",
      descriptionText1:
        "Now your customers will be able to choose if they want home delivery or store pickup when buying your products in your virtual store",
      descriptionText2:
        "This new modality can be easily activated or deactivated from the platform's backoffice. All customer orders now carry the specification of whether they should be delivered to your home or the customer will pick them up at your store",
    },
    "v0.13.0": {
      title: "Discount coupons",
      descriptionText1:
        "Through this new functionality you can give your followers coupons that when buying in your virtual store, they can activate to obtain personalized discounts on their purchases",
      descriptionText2:
        "You can generate as many coupons as you want, add specific discounts to them (fixed discount amounts or percentages of the total purchase), limit the number of uses and set an expiration date. Everything in an easy and intuitive way from your backoffice",
      improvementsText1:
        "We solved an issue related to the non-reading of delivery zones and locations when customers did not specify the delivery location when purchasing",
    },
    "v0.12.0": {
      title: "Blogs and galleries in your virtual store",
      descriptionText1:
        "With this new functionality it is possible to create personalized blog or image gallery pages, which your customers can enter from your virtual store",
      descriptionText2:
        "We call Blogs a page where you can write any type of article, being able to add images, titles, subtitles, quotes, among others. On the other hand, through Galleries, you will be able to publish photos (events, news, featured products, among others) in a beautiful and attractive format for your followers to see in your virtual store",
      improvementsText1:
        "We resolved an issue detected when displaying the total stock of a product in the product list, when it consists of multiple variants, each with a different stock",
    },
    "v0.11.0": {
      title: "Products volume",
      descriptionText1:
        "We added the option to specify the height, length, width and weight of each of your products, so that you have a more detailed product definition and a much more accurate way of calculating your shipping costs",
      li1: "We made a number of improvements to the performance of loading orders from your clients, so that now they will load and you will be able to manage them much faster",
      li2: "Problem detected when updating navigation links and that the changes were not automatically reflected in the backoffice, but that the app had to be reloaded to see them was solved",
    },
    "v0.10.0": {
      title: "Positioning of navigation links",
      descriptionText1:
        "We added the possibility of positioning the navigation links of your virtual store in the order you want",
    },
    "v0.9.0": {
      title: "Navigation links",
      descriptionText1:
        "In this new version of Grafito we have included the possibility of creating the navigation menus of your virtual store yourself. In this way, you can better customize the way your followers visit your virtual store, allowing you to better classify your categories and subcategories, highlight specific content of your brand and improve the experience in which your customers browse your store",
    },
    "v0.8.0": {
      title: "Images on custom pages",
      descriptionText1:
        "We include the option to insert images in the personalized pages of your virtual store. You can add multiple images within your content to make it more nutritious for your followers!",
    },
    "v0.7.0": {
      title: "Virtual store navigation bar orientation",
      descriptionText1:
        "In this version of Grafito we include the possibility that you can configure the navigation bar to be displayed horizontally or vertically, in such a way to offer a minimalist and a more explicit way of displaying your category links",
      improvementsText1:
        "We made multiple improvements in the handling of discounts by product and by variant",
    },
    "v0.6.0": {
      title: "Automatic signature of terms and conditions of use",
      descriptionText1:
        "We have encapsulated in a single document all our terms and conditions of use of the platform, which can be accepted digitally in your backoffice. This allows our brands to be onboarded with us much faster and frees them from unnecessary documentation",
      improvementsText1:
        "We made the process of creating or updating products and their variants much easier and more intuitive. We removed some unnecessary processes on the user side and made the workflow much faster",
    },
    "v0.5.0": {
      title: "Price and SKU per product variant",
      descriptionText1:
        "Now each product variant can carry its own price and SKU, regardless of the product it belongs to",
      descriptionText2:
        "This will provide you with much more flexibility when creating and classifying your products, as well as a better user experience for your followers when choosing your products",
      li1: "We define as automatic order criteria to list your followers, the date of their last interaction with your brand (purchase in this case)",
      li2: "We include the optional telephone and Citizen ID fields for your followers",
      li3: "We fixed some visual issues when displaying the product category listing from small devices",
    },
    "v0.4.0": {
      title: "Orders state management",
      descriptionText1:
        "We have included a very important functionality for our brands: the possibility for you to manage the life cycle of your orders and for your customers to be kept informed in real time of their changes",
      descriptionText2:
        "This means that you can now manage the status of your orders (received, in preparation, in dispatch, dispatched, canceled) and we take care that your customers are kept up to date with each movement",
    },
    "v0.3.0": {
      title: "UI/UX redesign",
      descriptionText1:
        "We have made important improvements in the design of the platform, both for your backoffice and virtual store. We have introduced a completely new interface design, which will give you and your followers a totally intuitive, fresh and pleasant experience to use",
    },
    "v0.2.0": {
      title: "Personalized notes for checkout",
      descriptionText1:
        "In this version we introduce the possibility that you can add notes, whatever the announcement you want to give, for your customers at the checkout of their purchase",
      descriptionText2:
        "Simply add the text from your backoffice and your customers will see it when they checkout their purchase",
      li1: "We resolved a bug related to the handling of statuses (activated or deactivated) of product categories. Previously the action of changing the status was not reflected in the virtual store, with the incorporated improvements the functionality returned to normal",
      li2: "We have improved the product creation process, so that the platform automatically refreshes the list of products after they are created",
    },
    "v0.1.0": {
      title: "Launch of our platform",
      descriptionText1:
        "Grafito.app launches its first version to the public. In this first version we include multiple fundamental functionalities for our brands, among which stand out: product and inventory management, order and payment management, customer management, basic sales statistics and a 100% manageable virtual store so that your followers they can know your brand and buy your products",
      descriptionText2:
        "The platform is made up of two applications, a back office and an online store with a personalized domain for each of our brands (with built-in shopping cart and payment process)",
    },
  },
  footer: {
    aboutLinkText: "About us",
    plansLinkText: "Plans",
    changelogLinkText: "Changelog",
    copyrightText: "© {{copyrightYear}} Grafito.app - Designed Worldwide",
  },
  localesSwitcher: {
    title: "Change language",
    description: "Select the language you prefer for our website.",
  },
};
