import { useTranslation } from "react-i18next";

import Typography from "app/components/Typography/Typography";

import {
  CompaniesContainer,
  CompaniesCardsContainer,
  CompaniesTitle,
  CompaniesAnchor,
  CompanyLogoImg,
} from "./Companies.styles";
import { COMPANIES } from "./Companies.constants";

export default function Companies() {
  const { t } = useTranslation();

  return (
    <CompaniesContainer>
      <CompaniesTitle>{t("home.companies.title")}</CompaniesTitle>

      <CompaniesCardsContainer>
        {COMPANIES.map((company, index) => (
          <CompaniesAnchor
            key={`company-${index}`}
            href={company.domain}
            target="_blank"
          >
            <CompanyLogoImg src={company.logo} />
          </CompaniesAnchor>
        ))}
      </CompaniesCardsContainer>

      <Typography as="p" variant="paragraph1" style={{ marginTop: 24 }}>
        {t("home.companies.andMoreText")}.
      </Typography>
    </CompaniesContainer>
  );
}
