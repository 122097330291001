import {
  PosterCardBlackMask,
  PosterCardContainer,
  PosterCardChildrenContainer,
} from "./PosterCard.styles";
import { TPosterCardProps } from "./PosterCard.types";

export default function PosterCard({
  as = "aside",
  children,
  style,
  className,
  gradientFrom,
  gradientTo,
  bgImg,
}: TPosterCardProps) {
  const background = bgImg
    ? `url(${bgImg}) center center / cover no-repeat`
    : `linear-gradient(${gradientFrom}, ${gradientTo})`;

  return (
    <>
      <PosterCardContainer
        as={as as any}
        className={className}
        style={{
          ...style,
          background,
        }}
      >
        <PosterCardChildrenContainer>{children}</PosterCardChildrenContainer>

        {bgImg && <PosterCardBlackMask />}
      </PosterCardContainer>
    </>
  );
}
