import OscarGrabadorLogo from "app/assets/images/companies-logos/oscargrabador-logo.png";
import LambraLogo from "app/assets/images/companies-logos/lambra-logo.png";
import VSutilLogo from "app/assets/images/companies-logos/vsutil-logo.png";
import BazardelaFortunaLogo from "app/assets/images/companies-logos/bazardelafortuna-logo.png";
import KasviplantasLogo from "app/assets/images/companies-logos/kasviplantas-logo.png";
import ReyChicharraLogo from "app/assets/images/companies-logos/reychicharra-logo.png";
import FresiasLogo from "app/assets/images/companies-logos/fresias-logo.png";
import EtniaLogo from "app/assets/images/companies-logos/etnia-logo.png";
import WoolieLogo from "app/assets/images/companies-logos/woolie-logo.png";
import CamiloLogo from "app/assets/images/companies-logos/camilo-logo.png";
import MingaLogo from "app/assets/images/companies-logos/minga-logo.png";
import JaponicaLogo from "app/assets/images/companies-logos/japonica-logo.png";
import PlantaeLogo from "app/assets/images/companies-logos/plantae-logo.png";
import ReimundoLogo from "app/assets/images/companies-logos/ram-logo.png";
import KeplantoLogo from "app/assets/images/companies-logos/keplanto-logo.png";
import VictoriaLogo from "app/assets/images/companies-logos/victoria-logo.png";
import SignoriLogo from "app/assets/images/companies-logos/signori-logo.png";
import BotanyLogo from "app/assets/images/companies-logos/botany-logo.png";

export const COMPANIES: { logo: string; domain: string }[] = [
  {
    logo: LambraLogo,
    domain: "https://www.lambra.cl",
  },
  {
    logo: OscarGrabadorLogo,
    domain: "https://www.oscargrabador.com/",
  },
  {
    logo: VSutilLogo,
    domain: "https://www.veronicasutil.com",
  },
  {
    logo: BazardelaFortunaLogo,
    domain: "https://www.bazardelafortuna.cl",
  },
  {
    logo: KasviplantasLogo,
    domain: "https://www.kasviplantas.com/",
  },
  {
    logo: ReyChicharraLogo,
    domain: "https://www.reychicharra.cl",
  },
  {
    logo: EtniaLogo,
    domain: "https://www.etnia.cl",
  },
  {
    logo: FresiasLogo,
    domain: "https://www.jabonesfresias.com/",
  },
  {
    logo: WoolieLogo,
    domain: "https://www.woolie.cl",
  },
  {
    logo: CamiloLogo,
    domain: "https://www.camiloxilografias.com/",
  },
  {
    logo: MingaLogo,
    domain: "https://www.mingakids.cl",
  },
  {
    logo: JaponicaLogo,
    domain: "https://www.japonicarocks.com/",
  },
  {
    logo: BotanyLogo,
    domain: "https://www.botany.cl/",
  },
  {
    logo: ReimundoLogo,
    domain: "https://www.reimundoamusri.com",
  },
  {
    logo: PlantaeLogo,
    domain: "https://www.plantaeeco.com",
  },
  {
    logo: SignoriLogo,
    domain: "https://www.signoricravatta.com/",
  },
  {
    logo: KeplantoLogo,
    domain: "https://www.keplanto.com",
  },
  {
    logo: VictoriaLogo,
    domain: "https://www.victoriadediogenes.com/",
  },
];
