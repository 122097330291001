import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, theme } from "kosmos-ui";
import GrafitoIsotypeIcon from "kosmos-ui/lib/icons/GrafitoIsotype";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import LocalesSwitcher from "app/components/LocalesSwitcher/LocalesSwitcher";

import {
  NavbarContainer,
  NavbarInnerContainer,
  NavbarLinksContainer,
  NavbarLink,
} from "./Navbar.styles";

export default function Navbar() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const [isInTop, setIsInTop] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const _isInTop = window.scrollY < 100;

      if (_isInTop !== isInTop) {
        setIsInTop(_isInTop);
      }
    });
  });

  return (
    <NavbarContainer isInTop={isInTop}>
      <NavbarInnerContainer>
        <NavbarLink to="..">
          <GrafitoIsotypeIcon height="32px" />
        </NavbarLink>

        {!mobile && (
          <NavbarLinksContainer>
            <NavbarLink to="about">{t("navbar.aboutLinkText")}</NavbarLink>
            <NavbarLink to="plans">{t("navbar.plansLinkText")}</NavbarLink>
            <NavbarLink to="changelog">
              {t("navbar.changelogLinkText")}
            </NavbarLink>
          </NavbarLinksContainer>
        )}

        <div style={{ display: "flex" }}>
          <LocalesSwitcher style={{ showDialogButton: { marginRight: 16 } }} />

          <Button
            variant="primary"
            size="small"
            style={{
              backgroundColor: theme.colorsPalette.blue.default,
              minWidth: "unset",
              padding: "8px 24px",
              fontSize: 14,
              fontWeight: 700,
            }}
            onClick={() =>
              window.location.replace("https://backoffice.grafito.app/signin")
            }
          >
            {t("navbar.signInButtonText")}
          </Button>
        </div>
      </NavbarInnerContainer>
    </NavbarContainer>
  );
}
