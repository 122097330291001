import { CarretIcon } from "kosmos-ui/lib";

import { useTranslation } from "react-i18next";

import Typography from "app/components/Typography/Typography";
import Button from "app/components/Button/Button";

import { HomeIntroContainer } from "./HomeIntro.styles";

export default function HomeIntro() {
  const { t } = useTranslation();

  return (
    <HomeIntroContainer>
      <Typography
        as="h1"
        variant="title1"
        style={{ textAlign: "center", maxWidth: 800, marginBottom: 40 }}
      >
        {t("home.intro.title1")} <br /> {t("home.intro.title2")}.
      </Typography>

      <Typography
        as="h2"
        variant="paragraph1"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        {t("home.intro.subtitle")}.
      </Typography>

      <Button
        variant="primary"
        href="https://galeria.grafito.app/"
        target="_blank"
        as="a"
      >
        {t("home.intro.goToPlatformBtnText")}{" "}
        <CarretIcon style={{ marginLeft: 8 }} height="14px" />
      </Button>
    </HomeIntroContainer>
  );
}
