export default function TeamworkIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 511.000000 547.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#FFFB00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,547.000000) scale(0.100000,-0.100000)"
        fill="url(#gradient)"
        stroke="none"
      >
        <path
          d="M2853 5426 l-28 -24 0 -267 0 -267 28 -24 c41 -35 88 -32 121 7 l26
31 0 253 0 253 -26 31 c-33 39 -80 42 -121 7z"
        />
        <path
          d="M1419 5081 c-31 -31 -36 -59 -17 -94 16 -31 256 -357 286 -389 68
-73 188 8 140 93 -34 59 -291 400 -308 409 -34 18 -70 11 -101 -19z"
        />
        <path
          d="M4298 5100 c-25 -15 -306 -402 -313 -432 -20 -80 89 -133 147 -70 30
31 250 329 279 375 27 44 24 74 -10 108 -31 30 -71 37 -103 19z"
        />
        <path
          d="M2364 4262 c-103 -36 -188 -120 -225 -223 -19 -52 -24 -58 -38 -47
-9 7 -36 29 -61 49 -200 162 -467 107 -572 -117 -32 -68 -42 -159 -25 -235 l7
-35 -48 -13 c-59 -15 -141 -68 -176 -114 -14 -18 -37 -59 -52 -91 -33 -72 -37
-184 -10 -256 10 -25 19 -52 22 -60 3 -10 -9 -21 -41 -34 -70 -28 -147 -104
-181 -178 -25 -55 -29 -75 -29 -153 0 -81 3 -97 31 -153 l30 -61 -52 -26 c-99
-48 -176 -155 -194 -268 -9 -58 3 -155 25 -197 l15 -28 -365 -308 c-254 -214
-367 -316 -374 -336 -26 -75 64 -141 131 -95 13 8 180 148 372 310 l349 295
166 -139 c175 -147 225 -175 326 -186 66 -7 165 19 227 58 118 76 184 229 158
363 -7 34 -18 72 -25 87 -13 25 -13 26 36 44 73 26 159 109 194 187 22 48 28
78 29 134 l1 72 51 5 c149 15 292 161 310 315 l6 57 57 2 c128 3 252 86 313
210 28 57 32 75 31 141 -1 85 -17 141 -62 206 l-28 43 259 219 c143 120 274
227 291 236 61 33 173 13 219 -38 46 -52 61 -148 32 -207 -9 -18 -45 -59 -80
-93 -55 -52 -64 -65 -64 -95 0 -41 27 -75 67 -85 20 -5 42 1 88 26 54 29 67
32 122 28 74 -5 125 -39 154 -102 45 -98 15 -171 -111 -277 -47 -39 -91 -83
-98 -96 -28 -51 18 -123 77 -123 15 0 44 12 66 27 59 40 149 45 207 10 55 -32
90 -93 90 -155 0 -79 -21 -111 -133 -208 -57 -49 -109 -95 -116 -104 -19 -23
-15 -75 8 -104 28 -35 72 -34 144 4 88 46 165 37 227 -27 42 -44 58 -79 58
-132 0 -84 -17 -102 -475 -489 -508 -429 -569 -472 -735 -519 -98 -27 -365
-25 -522 5 -64 12 -130 22 -147 22 -25 0 -133 -87 -641 -516 -336 -284 -618
-527 -626 -540 -32 -48 14 -124 75 -124 15 0 38 8 51 17 27 18 33 23 718 602
l453 382 127 -20 c70 -11 176 -24 237 -27 l110 -7 545 -457 c300 -250 556
-463 570 -473 37 -26 77 -21 111 12 34 34 37 68 9 103 -15 20 -931 794 -998
844 -11 8 -1 15 49 33 89 32 195 92 281 158 60 46 542 451 769 644 l56 48 349
-295 c192 -163 359 -303 372 -311 67 -46 157 20 131 95 -6 17 -45 59 -92 99
-45 38 -209 176 -364 307 l-282 238 14 28 c22 42 34 139 25 197 -18 113 -95
220 -194 268 l-52 26 30 61 c28 56 31 72 31 153 0 78 -4 98 -29 152 -33 73
-102 142 -173 175 -26 12 -50 24 -51 25 -2 1 6 28 18 60 25 65 30 164 12 228
-31 110 -127 210 -232 242 -30 9 -56 18 -57 18 -2 1 2 27 7 58 21 110 -19 234
-101 316 -130 130 -327 140 -484 26 -19 -13 -38 -6 -245 91 -124 57 -247 111
-274 119 -66 20 -149 18 -212 -3z m367 -254 c109 -51 195 -95 191 -99 -47 -44
-356 -299 -362 -299 -16 0 -245 201 -259 227 -22 43 -10 135 25 185 38 54 85
78 153 78 47 0 79 -12 252 -92z m-859 -66 c20 -9 186 -143 367 -296 354 -299
371 -318 371 -405 0 -53 -18 -88 -69 -134 -62 -56 -158 -63 -226 -15 -18 12
-175 144 -349 291 -228 193 -322 279 -336 307 -36 70 -19 172 37 222 52 47
141 60 205 30z m-261 -492 c24 -17 156 -126 294 -243 270 -229 295 -258 295
-340 0 -58 -17 -94 -64 -135 -62 -54 -157 -65 -218 -24 -14 9 -145 118 -292
241 -283 239 -306 265 -306 347 1 107 80 184 191 184 45 0 64 -5 100 -30z
m-234 -527 c56 -31 417 -344 440 -381 30 -48 31 -127 3 -182 -24 -46 -79 -86
-133 -96 -77 -13 -110 6 -333 194 -114 98 -217 192 -228 210 -45 79 -11 200
69 248 50 30 134 33 182 7z m-202 -557 c19 -8 118 -85 219 -170 147 -124 187
-163 200 -196 34 -84 10 -173 -60 -226 -45 -35 -131 -44 -184 -21 -19 9 -114
82 -210 164 -187 158 -220 200 -220 275 0 130 137 223 255 174z"
        />
      </g>
    </svg>
  );
}
