import { theme } from "kosmos-ui/lib";
import { CSSProperties } from "react";

export const localesSwitcherDialogStyles: CSSProperties = {
  width: "75vw",
  maxWidth: 400,
  maxHeight: 400,
  backgroundColor: theme.colorsPalette.black.default,
};

export const localesSwitcherLanguageButtonStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 8,
  width: 180,
};
