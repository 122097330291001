import styled from "styled-components";

export const StartTodayContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 40px;
  }
`;
