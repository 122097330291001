import { ReactNode } from "react";

import {
  LayersIcon,
  OctahedronIcon,
  CubeIcon,
  TetrahedronIcon,
} from "app/assets/icons";

const PLANS_BASE_FEATURES: { name: string }[] = [
  {
    name: "Own-domain Virtual Store",
  },
  {
    name: "Inventory management",
  },
  {
    name: "Orders execution",
  },
  {
    name: "Customers management",
  },
  {
    name: "Promotions and discounts",
  },
  {
    name: "Delivery management",
  },
  {
    name: "External apps integrations",
  },
  {
    name: "Virtual store management",
  },
];

export const PLANS: {
  icon: ReactNode;
  name: string | ReactNode;
  price: string;
  features: { name: string; isNew: boolean }[];
}[] = [
  {
    icon: <LayersIcon height="40px" style={{ margin: "8px 0" }} />,
    name: "Base plan",
    price: "30 USD/month",
    features: PLANS_BASE_FEATURES.map((feature) => ({
      ...feature,
      isNew: true,
    })),
  },
  {
    icon: <CubeIcon height="40px" style={{ margin: "8px 0" }} />,
    name: "Cubic plan",
    price: "60 USD/month",
    features: [
      {
        name: "All features from Base Plan",
        isNew: false,
      },
      {
        name: "Publish & sell on our Gallery",
        isNew: true,
      },
    ],
  },
  {
    icon: <TetrahedronIcon height="40px" style={{ margin: "8px 0" }} />,
    name: "Pyramid plan",
    price: "120 USD (one payment)",
    features: [
      {
        name: "All features from Base Plan",
        isNew: false,
      },
      {
        name: "Catalog photos (20)",
        isNew: true,
      },
    ],
  },
  {
    icon: <OctahedronIcon height="56px" />,
    name: "Prisma plan",
    price: "55 USD/month",
    features: [
      {
        name: "All features from Base Plan",
        isNew: false,
      },
      {
        name: "Communication plan and Social Media",
        isNew: true,
      },
    ],
  },
];
