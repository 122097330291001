import { Typography as BaseTypography } from "kosmos-ui/lib";
import { TTypographyProps } from "kosmos-ui/lib/components/Typography/types";

import { useTypographyStyles } from "./Typography.hooks";

export default function Typography({
  children,
  variant,
  style: baseStyle,
  ...props
}: TTypographyProps) {
  const style = useTypographyStyles({
    variant,
  });

  return (
    <BaseTypography
      variant={variant}
      style={{ ...style, ...baseStyle }}
      {...props}
    >
      {children}
    </BaseTypography>
  );
}
