export default function ValueIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 968.000000 946.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#FFFB00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,946.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          fill="url(#gradient)"
          d="M4826 9424 c-57 -20 -102 -58 -132 -110 -24 -40 -28 -62 -36 -187
-15 -215 -4 -720 17 -779 21 -61 83 -131 141 -158 59 -29 151 -32 216 -7 58
23 143 112 157 165 16 58 23 424 14 677 -8 226 -9 232 -36 277 -15 26 -44 60
-64 78 -68 56 -191 76 -277 44z"
        />
        <path
          fill="url(#gradient)"
          d="M3196 8850 c-105 -42 -159 -126 -159 -247 l0 -71 103 -184 c57 -101
147 -255 199 -343 85 -141 102 -163 145 -190 40 -24 62 -30 123 -34 89 -4 147
15 205 67 61 55 82 104 82 193 1 76 1 76 -66 191 -111 193 -311 517 -341 554
-63 76 -192 104 -291 64z"
        />
        <path
          fill="url(#gradient)"
          d="M6528 8860 c-58 -17 -98 -53 -151 -133 -90 -137 -339 -549 -363 -602
-62 -132 7 -284 151 -332 64 -21 152 -20 196 2 89 46 118 83 326 430 162 269
175 296 181 377 4 59 1 74 -23 123 -17 36 -42 67 -68 86 -69 50 -176 71 -249
49z"
        />
        <path
          fill="url(#gradient)"
          d="M3937 7306 c-65 -24 -133 -85 -503 -458 -259 -261 -367 -376 -382
-409 -28 -59 -30 -166 -3 -224 10 -22 56 -83 103 -135 130 -144 1587 -1591
1636 -1625 37 -25 55 -29 128 -33 139 -7 153 -1 332 168 281 264 1526 1523
1567 1584 39 58 40 60 40 150 0 146 -11 163 -283 419 -472 444 -572 531 -642
558 -48 18 -86 19 -1003 19 -770 -1 -959 -3 -990 -14z m1971 -789 l202 -202
-583 -583 -582 -582 -583 583 -583 583 193 202 193 201 770 1 770 0 203 -203z"
        />
        <path
          fill="url(#gradient)"
          d="M263 4320 c-46 -11 -117 -55 -149 -92 -47 -57 -67 -115 -68 -198 0
-122 57 -221 158 -272 37 -19 66 -22 271 -29 127 -4 658 -10 1180 -14 523 -4
1013 -10 1090 -15 155 -10 199 -22 334 -90 78 -39 107 -64 366 -314 430 -416
507 -471 779 -565 203 -70 221 -72 726 -81 250 -5 604 -9 786 -9 l331 -1 56
-40 c165 -118 184 -301 47 -448 -79 -85 12 -78 -1204 -86 l-1079 -7 -46 -24
c-106 -54 -166 -153 -166 -275 0 -119 58 -218 160 -273 l40 -22 1110 -3 c1232
-3 1148 -7 1316 63 216 91 406 284 489 500 31 80 35 86 69 95 276 73 1970 490
1992 490 42 0 78 -21 126 -73 85 -92 81 -186 -10 -271 -45 -43 -177 -111
-1022 -531 -1631 -811 -1777 -880 -2041 -960 -293 -89 -520 -125 -856 -132
-341 -8 -560 14 -855 87 -299 73 -345 92 -1558 637 -440 198 -865 388 -944
422 -410 179 -847 271 -1281 271 -132 0 -150 -2 -195 -24 -61 -29 -117 -86
-144 -146 -16 -34 -21 -66 -21 -130 0 -99 20 -153 78 -213 61 -64 101 -79 237
-89 504 -37 726 -85 1065 -230 91 -39 498 -217 905 -395 1326 -583 1416 -619
1680 -688 317 -83 493 -105 882 -112 341 -6 470 2 713 43 288 48 654 158 911
272 181 80 1090 527 2015 990 752 377 760 381 837 452 123 115 190 219 242
380 23 70 28 109 33 223 5 157 -5 226 -50 334 -76 184 -213 330 -393 421 -88
44 -157 62 -282 73 -143 12 -91 23 -1213 -255 -497 -123 -908 -222 -913 -219
-4 3 -33 44 -64 91 -117 182 -286 311 -503 385 l-75 25 -800 10 c-900 12 -845
7 -1020 92 -99 48 -103 51 -397 333 -163 157 -328 310 -365 340 -120 95 -259
169 -416 222 -165 55 -248 66 -602 75 -389 11 -2247 11 -2292 0z"
        />
      </g>
    </svg>
  );
}
