export default function CheapIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 424.000000 535.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#fffb00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,535.000000) scale(0.100000,-0.100000)"
        fill="url(#gradient)"
        stroke="none"
      >
        <path
          d="M1202 5320 c-205 -54 -365 -211 -429 -420 -15 -49 -17 -145 -20 -902
l-4 -848 -132 0 c-204 0 -316 -40 -428 -151 -155 -155 -193 -405 -91 -594 49
-90 1609 -2175 1677 -2242 126 -122 292 -168 463 -128 90 21 154 57 226 128
93 90 1648 2179 1694 2275 30 63 36 86 40 166 6 115 -7 176 -60 278 -47 90
-127 172 -210 212 -89 43 -160 56 -305 56 l-132 0 -4 848 c-3 935 0 882 -68
1021 -43 87 -162 207 -248 250 -35 18 -97 41 -139 52 -71 18 -117 19 -916 18
-795 0 -845 -1 -914 -19z m1791 -330 c56 -21 113 -72 143 -130 18 -33 19 -83
24 -980 5 -922 5 -946 25 -979 26 -44 62 -70 111 -81 21 -5 133 -10 247 -10
231 0 258 -6 299 -63 28 -39 35 -112 15 -160 -15 -35 -1549 -2109 -1606 -2170
-38 -42 -73 -57 -131 -57 -58 0 -93 15 -131 57 -63 67 -1594 2139 -1605 2172
-7 20 -13 47 -13 61 -1 41 34 105 70 131 33 23 40 24 279 29 273 6 285 9 337
77 l28 36 5 946 5 946 23 47 c13 27 42 64 64 83 78 66 39 63 940 64 793 1 819
0 871 -19z"
        />
        <path
          d="M1978 4129 c-16 -9 -18 -27 -18 -143 l0 -134 -52 -12 c-298 -68 -499
-392 -423 -683 50 -192 221 -359 421 -412 34 -9 125 -18 214 -22 135 -5 161
-9 202 -29 74 -37 120 -116 120 -203 0 -72 -12 -100 -63 -155 -63 -69 -105
-81 -285 -81 -147 0 -152 1 -191 27 -26 17 -49 44 -64 75 -12 26 -27 51 -33
55 -6 4 -70 8 -142 8 -159 0 -170 -5 -161 -76 24 -203 201 -382 407 -413 l45
-6 3 -130 c2 -108 5 -132 19 -142 21 -16 255 -18 284 -3 17 10 19 22 19 143
l0 132 74 23 c243 76 401 271 413 512 14 263 -145 492 -397 571 -50 16 -104
22 -237 26 -166 6 -174 7 -223 35 -127 73 -153 237 -54 347 67 74 106 86 290
86 147 0 152 -1 191 -27 26 -17 49 -44 64 -75 12 -26 27 -51 33 -55 17 -12
265 -9 287 2 25 14 25 59 -1 143 -52 169 -214 310 -382 335 l-53 7 -3 130 c-2
108 -5 132 -19 143 -21 15 -259 16 -285 1z"
        />
      </g>
    </svg>
  );
}
