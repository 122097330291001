import { gql } from "@apollo/client";

export const GET_COMPANIES_PUBLIC_PROFILE = gql`
  query GET_COMPANIES_PUBLIC_PROFILE_QUERY {
    getCompaniesPublicProfile {
      _id
      createdAt
      fantasyName
      domain
      logo
      totalPayments
    }
  }
`;
