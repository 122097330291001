import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Allows to scroll the app to top on every route change.
 */
export function useScrollToTop() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}
