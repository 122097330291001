import { OurPhilosophy, Team } from "./AboutUs.components";

export default function AboutUsSection() {
  return (
    <>
      <OurPhilosophy />

      <Team />
    </>
  );
}
