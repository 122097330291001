export default function RocketIcon({ height = "100px", style }: TIconProps) {
  return (
    <svg height={height} viewBox="0 0 580.000000 539.000000" style={style}>
      <defs>
        <linearGradient id="gradient">
          <stop stopColor="#fffb00" offset="0%" />
          <stop stopColor="#00FDFF" offset="100%" />
        </linearGradient>
      </defs>

      <g
        transform="translate(0.000000,539.000000) scale(0.100000,-0.100000)"
        fill="url(#gradient)"
        stroke="none"
      >
        <path
          d="M5370 5353 c-250 -13 -701 -116 -1055 -241 -380 -134 -716 -294
-1028 -488 -64 -41 -123 -74 -130 -74 -7 0 -94 29 -193 64 -223 80 -318 99
-489 99 -320 0 -624 -123 -844 -342 -118 -116 -732 -861 -760 -921 -23 -48
-26 -68 -26 -155 0 -88 3 -107 27 -157 29 -63 105 -145 159 -174 19 -10 140
-57 269 -105 129 -49 234 -92 233 -96 -1 -4 -45 -80 -99 -168 -211 -348 -302
-588 -264 -692 12 -32 1090 -1116 1132 -1139 100 -53 333 32 720 262 88 53
161 94 163 92 2 -2 40 -102 85 -223 45 -120 95 -245 112 -278 38 -71 91 -119
178 -161 58 -28 75 -31 156 -31 85 0 96 2 157 35 90 47 871 712 959 816 121
143 207 308 255 493 20 78 26 128 30 261 6 208 -11 300 -103 550 l-66 177 55
84 c333 511 599 1186 711 1799 52 285 57 440 17 523 -66 136 -185 199 -361
190z m21 -421 c-5 -110 -74 -456 -119 -605 l-17 -57 -288 288 -289 289 119 31
c218 58 571 120 592 105 3 -2 4 -25 2 -51z m-683 -631 c227 -225 412 -415 412
-424 0 -38 -174 -417 -270 -588 -113 -201 -262 -425 -378 -569 -163 -202 -425
-460 -727 -715 -165 -139 -428 -345 -442 -345 -4 0 -284 276 -622 614 l-614
614 24 33 c173 248 565 700 813 936 267 255 601 485 972 668 126 63 394 182
415 184 3 1 191 -183 417 -408z m-1990 16 c46 -13 87 -28 92 -33 6 -6 -31 -46
-90 -99 -233 -210 -597 -618 -860 -965 -56 -74 -107 -134 -114 -133 -47 10
-541 202 -541 211 0 13 613 737 687 810 109 109 260 190 423 226 105 23 295
16 403 -17z m259 -2884 c-41 -41 -497 -293 -529 -293 -17 0 -898 881 -898 898
0 29 154 315 256 473 l46 72 569 -569 c448 -448 566 -571 556 -581z m1770 749
c33 -224 -33 -461 -177 -639 -45 -56 -830 -736 -864 -749 -9 -3 -44 80 -113
272 l-100 278 129 98 c352 267 764 634 973 868 l90 101 23 -63 c13 -34 30
-109 39 -166z"
        />
        <path
          d="M3565 3940 c-225 -45 -417 -216 -497 -440 -19 -56 -23 -85 -23 -210
0 -132 3 -151 27 -218 37 -104 83 -175 159 -251 126 -126 255 -182 439 -189
227 -8 398 66 542 235 102 120 152 266 151 433 -1 185 -66 333 -203 464 -132
125 -287 187 -464 185 -50 -1 -109 -5 -131 -9z m272 -381 c110 -53 167 -149
165 -276 -1 -80 -34 -155 -93 -211 -142 -137 -384 -95 -477 82 -23 44 -27 63
-27 136 0 97 20 152 81 214 93 96 222 116 351 55z"
        />
        <path
          d="M905 1641 c-61 -28 -439 -415 -455 -465 -34 -104 43 -221 154 -233
83 -10 107 6 308 205 103 101 199 201 213 222 67 97 31 221 -80 271 -51 24
-90 23 -140 0z"
        />
        <path
          d="M1345 1181 c-16 -10 -168 -158 -337 -327 -270 -272 -308 -314 -318
-353 -41 -153 119 -284 258 -212 20 11 175 158 343 328 293 294 307 310 317
357 6 27 9 54 7 60 -2 6 -6 23 -9 38 -8 34 -59 94 -97 114 -40 20 -127 18
-164 -5z"
        />
        <path
          d="M1829 737 c-19 -8 -44 -20 -55 -28 -46 -35 -393 -397 -408 -426 -32
-64 -12 -156 46 -210 56 -52 150 -59 219 -14 24 15 127 113 229 217 149 151
188 197 198 230 15 50 15 59 2 106 -28 98 -139 158 -231 125z"
        />
      </g>
    </svg>
  );
}
