import styled from "styled-components";

export const ChangelogContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 152px 72px 88px 72px;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 112px 40px 40px 40px;
  }
`;

export const ChangelogSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 620px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ChangelogSubtitle = styled.h2`
  font-size: 24px;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colorsPalette.blue.dark}, ${theme.colorsPalette.cyan.default})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ChangelogChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 16px;
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  border: 1px solid ${({ theme }) => theme.colorsPalette.green.default};
  color: ${({ theme }) => theme.colorsPalette.green.default};
  margin-left: 16px;
`;

export const ChangelogUl = styled.ul`
  margin-left: 0px;
  padding: 0px;
  max-width: 620px;
  margin: auto;
  margin-bottom: 16px;
  width: 100%;
`;

export const ChangelogLi = styled.li`
  margin: 0px 0px 16px;
  list-style: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='1' viewBox='0 0 14 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='0.5' x2='14' y2='0.5' stroke='%23005cb3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 0px 0.9em;
  padding-left: 24px;
  color: #8f9ab7;
  line-height: 1.8;
`;
