import { createContext, ReactNode } from "react";

import { TCompaniesContext } from "./CompaniesContext.types";
import { MOCKED_COMPANIES_IDS } from "./CompaniesContext.constants";
import { useGetCompaniesPublicProfileQuery } from "api/graphql/resources/companies/companies.gql.queries";

export const CompaniesContext = createContext({
  companies: undefined,
  loadingCompaniesData: false,
} as TCompaniesContext);

export function CompaniesContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const {
    data: companiesPublicProfileData,
    loading: loadingCompaniesPublicProfileData,
  } = useGetCompaniesPublicProfileQuery();

  const companies =
    companiesPublicProfileData?.getCompaniesPublicProfile &&
    companiesPublicProfileData.getCompaniesPublicProfile.filter(
      (company: Distinct<TCompanyPublicProfile>) =>
        !MOCKED_COMPANIES_IDS.includes(company._id)
    );

  return (
    <CompaniesContext.Provider
      value={{
        companies,
        loadingCompaniesData: loadingCompaniesPublicProfileData,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
}
