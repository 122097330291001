import HomeIntro from "./components/HomeIntro/HomeIntro";
import Companies from "./components/Companies/Companies";
import Features from "./components/Features/Features";
import CompetitiveAdvantages from "./components/CompetitiveAdvantages/CompetitiveAdvantages";
import Testimonials from "./components/Testimonials/Testimonials";
import StartToday from "./components/StartToday/StartToday";

export default function Home() {
  return (
    <>
      <HomeIntro />

      <Companies />

      <Features />

      <CompetitiveAdvantages />

      <Testimonials />

      <StartToday />
    </>
  );
}
