import { useTranslation } from "react-i18next";
import { theme } from "kosmos-ui/lib";

import { useLayout } from "app/context/LayoutContext/LayoutContext.hooks";
import Typography from "app/components/Typography/Typography";
import veroSutilAvatar from "app/assets/images/veroSutilAvatar.jpg";
import andreaErrazurizAvatar from "app/assets/images/andreaErrazurizAvatar.png";
import reimundoMusriAvatar from "app/assets/images/reimundoMusriAvatar.jpg";
import barbaraCantoAvatar from "app/assets/images/barbaraCantoAvatar.jpg";
import isiDeLaSottaAvatar from "app/assets/images/isiDeLaSottaAvatar.jpg";
import franKegevicAvatar from "app/assets/images/franKegevicAvatar.jpg";

import {
  TestimonialsContainer,
  TestimonialsCardsContainer,
  TestimonialsCardsSubContainer,
  TestimonialCardBodyContainer,
  TestimonialCard,
  TestimonialAvatar,
} from "./Testimonials.styles";

export default function Testimonials() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  return (
    <TestimonialsContainer>
      <Typography as="h1" variant="title2">
        {t("home.testimonials.title")}.
      </Typography>

      <TestimonialsCardsContainer>
        <TestimonialsCardsSubContainer>
          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={barbaraCantoAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t1.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t1.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t1.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>

          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={isiDeLaSottaAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t2.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t2.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t2.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>

          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={andreaErrazurizAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t3.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t3.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t3.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>
        </TestimonialsCardsSubContainer>

        <TestimonialsCardsSubContainer>
          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={veroSutilAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t4.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t4.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t4.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>

          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={reimundoMusriAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t5.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t5.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t5.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>

          <TestimonialCard
            style={{
              backgroundColor: "#1C1D1F",
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "center" : "unset",
              padding: 32,
            }}
          >
            <TestimonialAvatar src={franKegevicAvatar} />

            <TestimonialCardBodyContainer>
              <Typography as="h1" variant="paragraph2">
                {t("home.testimonials.t6.actorNameText")}
              </Typography>

              <Typography
                as="h1"
                variant="paragraph2"
                style={{
                  fontSize: theme.fontSizes.regularSmall,
                  color: theme.colorsPalette.blue.default,
                  marginBottom: 8,
                }}
              >
                {t("home.testimonials.t6.actorRoleText")}
              </Typography>

              <Typography as="p" variant="paragraph3">
                {t("home.testimonials.t6.testimonialText")}.
              </Typography>
            </TestimonialCardBodyContainer>
          </TestimonialCard>
        </TestimonialsCardsSubContainer>
      </TestimonialsCardsContainer>
    </TestimonialsContainer>
  );
}
