import styled from "styled-components";

export const HomeIntroContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsPalette.black.darker};
  height: 100%;
  padding: 152px 72px 52px 72px;
  z-index: 2;

  @media (max-width: 600px) {
    width: unset;
    text-align: center;
    align-items: center;
    padding: 112px 40px 40px 40px;
  }
`;
